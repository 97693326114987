import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';


/**
 * Like Angular RouterLinkActive directive but looks for simple substring match.
 * It does not support dynamic routerLinks
 */
@Directive({
  selector: '[uvcRouterLinkActive]',
  exportAs: 'uvcRouterLinkActive',
})
export class RouterLinkActiveDirective implements OnDestroy {

  private classes: string[] = [];
  private routerEventsSubscription: Subscription;

  constructor(private router: Router, private element: ElementRef, private renderer: Renderer2) {
    this.routerEventsSubscription = router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  @Input()
  set uvcRouterLinkActive(data: string[]|string) {
    if (Array.isArray(data)) {
      this.classes = data as any;
    } else {
      this.classes = data.split(' ');
    }
  }

  @Input()
  routerLink: string|any[];

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe();
  }

  private update(): void {
    const url = typeof this.routerLink === 'string' ? this.routerLink : this.routerLink.join('/');
    const isActive = this.router.url.includes(url);

    this.classes.forEach(c => isActive ?
      this.renderer.addClass(this.element.nativeElement, c) : this.renderer.removeClass(this.element.nativeElement, c));
    let htmlNode = this.element.nativeElement as HTMLElement;

    // Try to open dropdown if there is any in the parent hierarchy
    if (isActive) {
      // We have only 2 level sidebar menu and the lower level is 4 elements from menu item that opens it.
      let maxTries = 4;
      while (htmlNode && maxTries >= 0) {
        if (!htmlNode.classList.contains('nav-dropdown')) {
          htmlNode = htmlNode.parentElement;
          maxTries--;
        } else {
          htmlNode.classList.add('open');
          break;
        }
      }
    }
  }
}
