import {Injectable, Inject} from '@angular/core';
import {StorageService} from './storage.service';
import { DOCUMENT } from '@angular/common';
import { ReplaySubject, Observable, fromEventPattern } from 'rxjs';
import { first, filter, switchMap, map, tap } from 'rxjs/operators';

const THEME_ELEMENT_ID = 'theme-link';
const FAVICON_ELEMENT_ID = 'favicon';
const AVAILABLE_THEMES = ['system', 'theme-light', 'theme-dark'];
declare global {
    interface Window {
        uvSystemTheme: () => string; // declared on index.html
        uvDarkThemeMatcher: MediaQueryList;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private currentThemeSubject = new ReplaySubject<{themeName: string, themeOption: string}>(1);
    public readonly themeOptions = AVAILABLE_THEMES;

    constructor(@Inject(DOCUMENT) private document: Document, private storageService: StorageService) {
        // Initial theme definitions need to be set before angular loads. See index.html for details.
        // A script there reads from localstorage or OS settings and set class on html tag.
        this.loadTheme(this.storageService.getSelectedTheme() || 'system');
        this.setupOSThemeListener();
    }

    /**
     * @returns true if browser runs on Android
     */
    public isAndroid() {
        return navigator.userAgent.match(/Android/i);
    }

    /**
     * @returns true if browser runs on iOS
     */
    public isIOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    public currentTheme(): Observable<{themeName: string, themeOption: string}> {
        return this.currentThemeSubject.asObservable();
    }

    public changeTheme(theme: string): void {
        if (!AVAILABLE_THEMES.includes(theme)){
            throw new Error(`Invalid theme: "${theme}"`);
        }

        this.loadTheme(theme);
        this.storageService.setSelectedTheme(theme !== 'system' ? theme : '');
    }

    private loadTheme(themeOption: string): void {
        const themeName = (themeOption === 'system') ? window.uvSystemTheme() : themeOption;

        // simply changing href attribute wont trigger load event. Needs replacement.
        const existingThemeLink = this.document.getElementById(THEME_ELEMENT_ID) as HTMLLinkElement;
        const newThemeLink = this.document.createElement('link');
        newThemeLink.id = THEME_ELEMENT_ID;
        newThemeLink.rel = 'stylesheet';
        newThemeLink.href = `/${themeName}.css`;
        newThemeLink.addEventListener('load', () => {
            if (existingThemeLink){
                existingThemeLink.remove();
            }
            // cleanup html classes to avoid confusion
            const htmlEl = this.document.getElementsByTagName('html')[0];
            if (!htmlEl.classList.contains(themeName)){
                htmlEl.classList.remove(...AVAILABLE_THEMES);
                htmlEl.classList.add(themeName);
            }
            this.currentThemeSubject.next({themeOption, themeName});
        });
        this.document.getElementsByTagName('head')[0].appendChild(newThemeLink);
    }

    private setupOSThemeListener(){
        fromEventPattern<MediaQueryListEvent>(
            handler => window.uvDarkThemeMatcher.addEventListener('change', handler)
        ).pipe(
            tap(() => this.refreshFavicon()),
            switchMap(() => this.currentTheme().pipe(first(), map(({themeOption}) => themeOption))),
            filter(themeOption => themeOption === 'system')
        ).subscribe(() => this.loadTheme('system'));
    }

    private refreshFavicon() {
        const favicon = this.document.getElementById(FAVICON_ELEMENT_ID) as HTMLLinkElement;
        favicon.href = favicon.href.replace(/(.*)[\?\d+]?/, `$1?${new Date().getTime()}`);
    }
}
