import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UserAccount, UserRole } from '../../../../service/service.model';
import { UserService } from '../../../../service/services/user.service';

@Component({
  selector: 'uvc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SideBarComponent implements OnInit {

  @ViewChildren('navDropdown')
  navDropdowns: QueryList<ElementRef<HTMLElement>>;

  account: UserAccount;

  UserRole = UserRole;

  constructor(public userService: UserService) {  }

  ngOnInit() {
    this.userService.currentUserAccount$().subscribe(account => {
      this.account = account;
    });
  }

  toggleNavDropdown(elem: HTMLElement) {
    elem.classList.toggle('open');
    // open/close other dropdowns
    if (this.navDropdowns) {
      this.navDropdowns.forEach(navDropdown => {
        if (navDropdown.nativeElement !== elem) {
          navDropdown.nativeElement.classList.remove('open');
        }
      });
    }
  }
}
