<uvc-base-modal [title]="title" [footer]="footer" [showClose]="false" #parent>
    <input type="checkbox" id="user-agreement-chck" data-test-id="user-agreement-checkbox" #checkbox>
    I acknowledge that I have read and agree to the <a [href]="PORTAL_ACCESS_AGREEMENT_LINK" target="_blank">UV Angel Cloud&trade; Access Agreement</a>.
</uvc-base-modal>

<ng-template #footer>
    <div class="d-flex w-100 justify-content-between">
        <button
            [attr.aria-label]="logout"
            data-test-id="user-agreement-logout-btn"
            class="btn btn-danger"
            type="button"
            (click)="close('LOGOUT')">Log Out</button>
        <button
            [disabled]="!checkbox.checked"
            [class.not-agreed]="!checkbox.checked"
            [attr.aria-label]="continue"
            data-test-id="user-agreement-continue-btn"
            class="btn btn-primary"
            type="button"
            (click)="close('AGREE')">Continue</button>
    </div>
</ng-template>
