import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
  AccountPreferences, AccountPreferencesInput, PaginatedInput, PaginatedResponse,
  PaginatedResponseData, ScopedUserAccount, ScopedUserAccountData, SpecificAccountScopeData,
  toUserRoleList, User,
  UserAccount, UserAccountData, UserData, UserPreferences, UserRole,
  UserRoleData, UserStatusFilter
} from '../service.model';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { UserSession } from './user-session.service';

const accountRolesFragment = `
  account_read
  account_write
  users_read
  users_write
  location_read
  location_write
  report_read
  report_write
  device_read
  device_write
`;

const globalRolesFragment = `
globalRoles {
  account_create
  account_remove
  ${accountRolesFragment}
}
`;

const accountPreferencesFragment = `
preferences {
  logo_image_url
  unit_of_measure
  preferred_timezone
}
`;

const scopedAccountFields = `
  id
  name
  createdAt
  locationTypes
  updatedAt
  numLocations
  numMembers
  updatedBy {
    id
  }
  createdBy {
    id
  }
  rootLocation {
    id
    name
    type
    description
  }
  datasources {
    id
    datasourceType
    datasourceName
    datasourceDescription
  }
  deviceTypes
  userAccountScope {
    roles {
      ${accountRolesFragment}
    }
    rootLocation {
      id
      name
      type
      description
    }
  }
  ${accountPreferencesFragment}
`;

const getCurrentUserQuery = gql`
  query getCurrentUserInfo {
    currentUser {
      id
      firstName
      lastName
      agreements {
        type
        version
        agreedAt
      }
      preferences {
        defaultAccountId
      }
      memberAccounts {
        ${scopedAccountFields}
      }
      ${globalRolesFragment}
    }
  }`;

// const getCurrentUserAccountRoles = gql`
//   query getAccountScope($accountId: ID!) {
//     currentUser {
//       accountScope(accountId: $accountId) {
//         roles {
//           account_read
//           account_write
//           users_read
//           users_write
//           location_read
//           location_write
//           report_read
//           report_write
//           device_read
//           device_write
//         }
//         rootLocationId
//       }
//     }
//   }`;

  // TODO confirm if Global Roles is needed
const inviteUserMutation = gql`
  mutation inviteUser($emails: [String]!, $roles: GlobalRolesInput!, $accountId: ID, $rootLocationId: ID) {
    inviteUsersToAccount(emails: $emails, roles: $roles, accountId: $accountId, rootLocationId: $rootLocationId){
      id
      firstName
      lastName
      createdAt
      lastLogin
      status
      email
    }
   }`;

const getAccountUserList = gql`
  query userList ($accountId: ID!, $searchQuery: String, $status: [UserStatus], $pagination: PaginatedInput) {
    users(accountIds: [$accountId], filters: {searchQuery: $searchQuery, status: $status}, pagination: $pagination) {
      items: users {
        id
        firstName
        lastName
        createdAt
        updatedAt
        lastLogin
        status
        email
        accountScope(accountId: $accountId) {
          roles {
            ${accountRolesFragment}
          }
        }
      }
    	totalCount
    	nextToken
    }
  }`;

// A function that returns a gql based on the presence/absence of accountId.
// When the input filter doesn't have accountId, we can not fetch user's accountScope
const usersAdminSearchQuery = (accountId: string) => gql`
  query usersAdminSearch($filters: AdminUserSearchFiltersInput, $sort: AdminUserSearchSortInput, $pagination: PaginatedInput) {
    usersAdminSearch(filters: $filters, sort: $sort, pagination: $pagination) {
      items: users {
        id
        firstName
        lastName
        createdAt
        updatedAt
        lastLogin
        status
        email
        ${
          // If we have accountId we can fetch accountScope. Otherwise we have to fetch memberAccounts.
          // We can not fetch both.
          accountId ? `
          accountScope(accountId: "${accountId}") {
            roles {
              ${accountRolesFragment}
            }
          }
          ` : `
          memberAccounts {
            id
            name
          }
          `
        }
      }
      totalCount
      nextToken
    }
  }`;

  // TODO confirm if Global Roles is needed
const updateUser = gql`
  mutation updateUser($firstName: String!, $lastName: String!, $userId: ID!) {
    updateUser(userId: $userId, firstName: $firstName, lastName: $lastName){
        id
        firstName
        lastName
        createdAt
        lastLogin
        status
        email
      }
  }`;

  // TODO confirm if Global Roles is needed
const updateUserRoles = gql`
  mutation updateUserRoles($userId: ID!, $accountId: ID!, $roles: [Role]!) {
    updateUserRolesForAccount(userId: $userId, accountId: $accountId, roles: $roles){
        id
        firstName
        lastName
        createdAt
        lastLogin
        status
        email
      }
  }`;

const getAccounts = gql`
  query getAccounts($searchString: String!, $searchLanguageCode: String, $pagination: PaginatedInput) {
    accounts(searchString: $searchString, searchLanguageCode: $searchLanguageCode, pagination: $pagination) {
      items: accounts {
        id,
        name,
        numLocations,
        numMembers,
      },
      totalCount,
      nextToken
    }
  }
`;

const getAccount = gql`
  query getAccount($accountId: ID!) {
    account(accountId: $accountId) {
      id
      name
      createdAt
      locationTypes
      updatedAt
      numLocations
      numMembers
      updatedBy {
        id
      }
      createdBy {
        id
      }
      rootLocation {
        id
        name
        type
        description
      }
      datasources {
        id
        datasourceType
        datasourceName
        datasourceDescription
      }
      deviceTypes
      ${accountPreferencesFragment}
    }
  }
`;

const getScopedAccount = gql`
  query getAccount($accountId: ID!) {
    account(accountId: $accountId) {
      ${scopedAccountFields}
    }
  }
`;


const createAccount = gql`
  mutation createAccount($name: String!, $locationTypes: [String]!) {
    createAccount(name: $name, locationTypes: $locationTypes){
      id
      name
      locationTypes
      rootLocation {
        id
        name
        type
        description
      }
      datasources {
        id
        datasourceType
      }
    }
  }`;

const getUserDetails = gql`
  query getUserDetails($id: ID!) {
    user(userId: $id) {
      id
      firstName
      lastName
      email
      ${globalRolesFragment}
    }
  }`;

const getUserDetailsWithAccountScope = gql`
  query getUserDetails($id: ID!, $accountId: ID!) {
    user(userId: $id) {
      id
      firstName
      lastName
      email
      ${globalRolesFragment}
      accountScope(accountId: $accountId) {
        roles {
          ${accountRolesFragment}
        }
        rootLocation {
          id
          name
          type
          description
        }
      }
    }
  }`;


const getUserDetailsWithoutGlobalRoles = gql`
  query getUserDetails($id: ID!) {
    user(userId: $id) {
      id
      firstName
      lastName
      email
    }
  }`;

const getUserDetailsWithAccountScopeWithoutGlobalRoles = gql`
  query getUserDetails($id: ID!, $accountId: ID!) {
    user(userId: $id) {
      id
      firstName
      lastName
      email
      accountScope(accountId: $accountId) {
        roles {
          ${accountRolesFragment}
        }
        rootLocation {
          id
          name
          type
          description
        }
      }
    }
  }`;

const resendUserInvitation = gql`
  mutation resendAccountInvitationToUser($email: String!, $accountId: ID!) {
    resendAccountInvitationToUser(email: $email, accountId: $accountId) {
      id
    }
  }
`;

const unregisterUser = gql`
  mutation unregisterUser($userId: ID!, $accountId: ID!) {
    unregisterUser(userId: $userId, accountId: $accountId){
      id
      firstName
      lastName
    }
  }`;

const updateGlobalUserRoles = gql`
  mutation updateGlobalUserRoles($userId: ID!, $roles: GlobalRolesInput!) {
    updateGlobalUserRoles(userId: $userId, roles: $roles){
      id
      firstName
      lastName
    }
  }`;

const updateUserRolesForAccount = gql`
  mutation updateUserRolesForAccount($userId: ID!, $accountId: ID!, $roles: AccountRolesInput!, $rootLocationId: ID) {
    updateUserRolesForAccount(userId: $userId, accountId: $accountId, roles: $roles, rootLocationId: $rootLocationId){
      id
      firstName
      lastName
    }
  }`;

const agreeOnAgreement = gql`
  mutation agreeOnUserAgreement($type: UserAgreementType!, $version: String!) {
    agreeOnUserAgreement(userAgreement: {type: $type, version: $version}){
      id
    }
  }`;

const updateUserPreferences = gql`
  mutation updateUserPreferences($preferences: UserPreferencesInput) {
    updateUserPreferences(preferences: $preferences) {
      id,
      preferences {
        defaultAccountId
      }
    }
  }
`;

const updateAccountPreferences = gql`
  mutation updateAccountPreferences($accountId: ID!, $attributesToUpdate: AccountUpdateInput!) {
    updateAccount(accountId: $accountId, attributesToUpdate: $attributesToUpdate) {
      id
    }
  }
`;

// Special account meant to signal that user has no account selected.
// At this point in project I can not throw error into currentUserAccount subject
export const NO_ACCOUNT = new ScopedUserAccount({id: 'NO ACCOUNT', name: 'NO ACCOUNT'}, []);

export const PORTAL_ACCESS_AGREEMENT = 'PORTAL_ACCESS_AGREEMENT';
export const PORTAL_ACCESS_AGREEMENT_CURR_VERSION = '1.0';

export interface SortBy<T> {
  field: T;
  direction: 'ASC' | 'DESC';
}

/**
 * This service offers subscriptions to User and UserAccount, and a way to trigger reloading of the User.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  // Emit User object in ReplaySubject with size 1 instead of BehaviorSubject, because BehaviorSubject requires an initial
  // value, which we do not have. We want the rest of the app to just wait for the first User object.
  private userSubject = new ReplaySubject<User>(1);
  private currentAccountSubject = new ReplaySubject<ScopedUserAccount>(1);

  // Current user and current user account
  private _currentUser: User;
  private _currentUserAccount: ScopedUserAccount;

  private defaultLanguageCode: string;

  constructor(
    private apollo: Apollo,
    private toastrService: ToastrService,
    private authService: AuthService,
    private storageService: StorageService,
    @Inject(LOCALE_ID) public locale: string) {

    // extract only language code from locale
    this.defaultLanguageCode = locale.indexOf('-') > -1 ? locale.substring(0, locale.indexOf('-')) : locale;

    if (this.authService.isAuthenticated()) {
      console.debug('[User] User authenticated during startup; triggering GraphQL reload of user.');
      this.reloadUser();
    }

    this.storageService.currentSession().subscribe((newSession: UserSession) => {
      if (newSession.isValid()) {
        console.debug('[User] stored session has changed');
        this.reloadUser();
      }
    });

    this.currentUser$().subscribe(user => {
      this._currentUser = user;
      this.storageService.setCurrUserId(user?.id);
    });
    this.currentUserAccount$().subscribe(acct => this._currentUserAccount = acct);
  }

  ngOnDestroy() {
    this.userSubject.complete();
    this.currentAccountSubject.complete();
  }

  public reloadUser() {
    console.debug('[User] Reloading current user');
    this.loadCurrentUser().subscribe(
        user => {}, error => {
          console.error(error);
          this.toastrService.error('Could not fetch your user details. Please try logging in again.', 'Error');
        }
    );
  }

  private loadCurrentUser(ignoreCache = false): Observable<User> {
    let loadedUser: User;
    return this.apollo.query<{currentUser: UserData}>({
      query: getCurrentUserQuery,
      fetchPolicy: (ignoreCache ? 'no-cache' : 'cache-first')
    }).pipe(
      map(({ data }) => {
        if (!data?.currentUser) {
          throw new Error(`Could not create user: GraphQL returned ${data}`);
        }
        return new User(data.currentUser);
      }),
      tap(user => {
        this.userSubject.next(user);
        loadedUser = user;
      }),
      switchMap(user => {
        // Try to fetch user's last selected account
        const lastSelectedAccountId = this.storageService.getSelectedAccountId();

        const fetchFirstMemberAccount$ = of(user).pipe(
          switchMap(currentUser => {
            if (!currentUser.accounts.length) {
              // Hard error
              this.currentAccountSubject.error('User is not assigned to any account');
              return of(undefined);
            }
            return of(currentUser.accounts[0]);
          })
        );

        // Do we know what was last selected account?
        if (lastSelectedAccountId) {
          // Try to find it inside member accounts otherwise load it from backend
          const foundMemberAccount = user.accounts.find(acc => acc.id === lastSelectedAccountId);
          const lastSelectedAccount$ = foundMemberAccount ? of(foundMemberAccount) :
            this.getScopedAccount(lastSelectedAccountId, true).pipe(
              catchError(error => {
                // If it fails then try to fetch first of user's accounts
                this.storageService.setSelectedAccountId(undefined);
                return fetchFirstMemberAccount$;
              })
            );
          return lastSelectedAccount$;
        } else {
          // We don't know last user's selected account. Does he have a defaultAccountId?
          if (user.preferences.defaultAccountId) {
            const foundMemberAccount = user.accounts.find(acc => acc.id === user.preferences.defaultAccountId);
            const defaultAccount$ = foundMemberAccount ? of(foundMemberAccount) :
              this.getScopedAccount(user.preferences.defaultAccountId, true).pipe(
                catchError(error => {
                  // If it fails then try to fetch first of user's accounts
                  this.storageService.setSelectedAccountId(undefined);
                  return fetchFirstMemberAccount$;
                }),
              );
            return defaultAccount$;
          } else {
            // No default accoun id = load first member account
            return fetchFirstMemberAccount$;
          }
        }
      }),
      tap(account => {
        if (account) {
          // Insert account into current user's accounts array for permission checking to work on account level
          if (!loadedUser.accounts.find(acct => acct.id === account.id)) {
            loadedUser.accounts.push(account);
          }
          this.setCurrentUserAccount(account);
        }
      }),
      map(() => loadedUser)
    );
  }

  public currentUser$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public currentUserAccount$(): Observable<ScopedUserAccount> {
    return this.currentAccountSubject.asObservable();
  }

  /**
   * Check if current user has all roles in current account.
   * roleType === 'GLOBAL' -> test only in user's global roles otherwise look also into account roles.
   */
  public currentUserHasRole(roles: UserRole | UserRole[], roleType: 'GLOBAL' | 'BOTH' = 'BOTH') {
    if (!this._currentUser) {
      return false;
    }

    return this._currentUser.hasRole(roles, roleType === 'BOTH' ? this._currentUserAccount?.id : undefined);
  }

  /**
   * Check if current user has any of the roles in current account.
   * roleType === 'GLOBAL' -> test only in user's global roles otherwise look also into account roles.
   */
  public currentUserHasAnyRole(roles: UserRole[], roleType: 'GLOBAL' | 'BOTH' = 'BOTH') {
    if (!this._currentUser) {
      return false;
    }

    return this._currentUser.hasAnyRole(roles, roleType === 'BOTH' ? this._currentUserAccount?.id : undefined);
  }

  /**
   * User is a UVA Admin if he has any global role OTHER than ACCOUNT_CREATE since all users have ACCOUNT_CREATE
   */
  public currentUserIsUVAdmin() {
    if (!this._currentUser) {
      return false;
    }

    const hasAccountCreate = this._currentUser.hasRole(UserRole.ACCOUNT_CREATE);
    if (this._currentUser.globalRoles.length > 1 || (this._currentUser.globalRoles.length === 1 && !hasAccountCreate)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Set current account for current user.
   * You can subscribe to return value to observe errors.
   * The returned observable emits only when error happens and automatically completes.
   */
  public setCurrentUserAccount(account: ScopedUserAccount): Observable<Error> {
    const result$ = new ReplaySubject<Error>(1);
    this.currentUser$().pipe(
      map(user => {
        // update currentUser.accounts with the new account for permissions checking to work properly
        const acctId = user.accounts.findIndex(acct => acct.id === account.id);
        if (acctId >= 0) {
          user.accounts[acctId] = account;
        } else {
          user.accounts.push(account);
        }
        return account;
      }),
      take(1),
      finalize(() => result$.complete()),
    ).subscribe(
      acc => {
        this.storageService.setSelectedAccountId(acc.id);
        this.storageService.addAccountToLSA(acc.name, acc.id);
        this.currentAccountSubject.next(acc);
      },
      err => result$.next(err)
    );

    return result$.asObservable();
  }

  public createAccount(account: UserAccountData): Observable<boolean> {
    return this.apollo.mutate<{createAccount: UserAccountData}>({
      mutation: createAccount,
      variables: {name: account.name, locationTypes: account.locationTypes}
    }).pipe(
      mergeMap(({data}) => {
        // select account and reload user before responding
        const acc = new UserAccount(data.createAccount);
        this.storageService.setSelectedAccountId(acc.id);
        this.storageService.addAccountToLSA(acc.name, acc.id);
        return this.loadCurrentUser(true).pipe(
          catchError(err => of(false)),
          map(() => true)
        );
      })
    );
  }

  public removeUserFromAccount(userId: string, accountId: string): Observable<User> {
    return this.apollo.mutate<{unregisterUser: UserData}>({
      mutation: unregisterUser,
      variables: {userId, accountId}
    }).pipe(
      map(({data}) => new User(data.unregisterUser))
    );
  }

  public getUserDetails(id: string, accountId: string, ignoreCache = false) {
    // Current backend will return server error if an user without global user_write permission
    // attempts to fetch globalRoles of another user.
    let query;
    if (this._currentUser.hasRole(UserRole.USERS_WRITE) || id === this._currentUser.id) {
      query = !!accountId ? getUserDetailsWithAccountScope : getUserDetails;
    } else {
      query = !!accountId ? getUserDetailsWithAccountScopeWithoutGlobalRoles : getUserDetailsWithoutGlobalRoles;
    }

    return this.apollo.query({
      query,
      variables: { id, accountId },
      fetchPolicy: (ignoreCache ? 'no-cache' : 'cache-first')
    }).pipe(
      map(({data}: any) => {
        if (accountId && data.user.accountScope) {
          const acctScope: SpecificAccountScopeData = {
            account: {
              id: accountId
            },
            accountScope: data.user.accountScope
          };
          return new User(data.user, [acctScope]);
        } else {
          return new User(data.user);
        }
      })
    );
  }

  public updateGlobalUserRoles(userId: string, roles: Partial<UserRoleData>): Observable<User>{
    return this.apollo.mutate<{updateGlobalUserRoles: UserData}>({
      mutation: updateGlobalUserRoles,
      variables: {userId, roles}
    }).pipe(
      map(({data}) => new User(data.updateGlobalUserRoles))
    );
  }

  public updateUserRolesForAccount(
    userId: string,
    accountId: string,
    roles: Partial<UserRoleData>,
    rootLocationId: string): Observable<User>{
    return this.apollo.mutate<{updateUserRolesForAccount: UserData}>({
      mutation: updateUserRolesForAccount,
      variables: {userId, accountId, roles, rootLocationId}
    }).pipe(
      map(({data}) => new User(data.updateUserRolesForAccount))
    );

  }

  /**
   * Get accounts that match search criteria.
   * The response contains only record returned in current search.
   */
  public getAccounts(
    searchString: string = '',
    searchLanguageCode: string = '',
    pagination?: PaginatedInput): Observable<PaginatedResponse<UserAccount, UserAccountData>> {

    searchLanguageCode = searchLanguageCode || this.defaultLanguageCode;
    return this.apollo.query<{accounts: PaginatedResponseData<UserAccountData>}>({
      query: getAccounts,
      variables: {
        searchString,
        searchLanguageCode,
        pagination: pagination || { maxResults: 1 },
      },
      fetchPolicy: (!!pagination ? 'no-cache' : 'cache-first')
    }).pipe(
      map(response => new PaginatedResponse<UserAccount, UserAccountData>(response.data.accounts, UserAccount))
    );
  }

  /**
   * Get account with given account id.
   * Returns full account data. Doesn't validate any permissions of frontend.
   * @param accountId account id
   */
  public getAccount(accountId: string, skipCache = false): Observable<UserAccount> {
    return this.apollo.query<{account: UserAccountData}>({
      query: getAccount,
      variables: {accountId},
      fetchPolicy: (skipCache ? 'no-cache' : 'cache-first')
    }).pipe(
      map(response => new UserAccount(response.data.account))
    );
  }

  /**
   * Get account with given account id.
   * Returns full account data including current user permissions for given account. Doesn't validate any permissions of frontend.
   * @param accountId account id
   */
  public getScopedAccount(accountId: string, skipCache = false): Observable<ScopedUserAccount> {
    return this.apollo.query<{account: ScopedUserAccountData}>({
      query: getScopedAccount,
      variables: {accountId},
      fetchPolicy: (skipCache ? 'no-cache' : 'cache-first')
    }).pipe(
      map(response => new ScopedUserAccount(response.data.account, toUserRoleList(response.data.account.userAccountScope.roles))),
      tap(acct => {
        // Enrich current user's accounts. Useful because we know user account permissions.
        const acctIdx = this._currentUser.accounts.findIndex(a => a.id === acct.id);
        if (acctIdx >= 0) {
          this._currentUser.accounts[acctIdx] = acct;
        } else {
          this._currentUser.accounts.push(acct);
        }
      })
    );
  }

  public inviteUser(emails: string[], roleData: UserRoleData, accountId?: string, rootLocationId?: string): Observable<UserData[]> {
    return this.apollo.mutate<{inviteUsersToAccount: UserData[]}>({
      mutation: inviteUserMutation,
      variables: {emails, accountId, rootLocationId, roles: roleData}
    }).pipe(
      map(({data}) => {
        return data.inviteUsersToAccount;
      })
    );
  }

  public signAgreement(type: string, version: string): Observable<boolean> {
    return this.apollo.mutate<{agreeOnAgreement: User}>({
      mutation: agreeOnAgreement,
      variables: {type, version}
    }).pipe(map(() => true));
  }

  public resendInvitation(email: string, accountId: string) {
    return this.apollo.mutate<{resendAccountInvitationToUser: UserData}>({
      mutation: resendUserInvitation,
      variables: {email, accountId}
    }).pipe(
      map(({data}) => new User(data.resendAccountInvitationToUser))
    );
  }

  public getAccountUsers(account: UserAccount, searchQuery: string, status: UserStatusFilter[], nextToken?: string, maxResults = 20):
      Observable<PaginatedResponseData<UserData & SpecificAccountScopeData>> {
    const pagination = { nextToken, maxResults };
    return this.apollo.query({
      query: getAccountUserList,
      variables: {
        accountId: account.id,
        searchQuery,
        status: status ? status.map(s => UserStatusFilter[s]) : undefined,
        pagination
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map(({data}: any) => data.users as PaginatedResponseData<UserData & SpecificAccountScopeData>),
    );
  }

  public usersAdminSearch(accountId?: string, searchQuery?: string, status?: UserStatusFilter[],
                          createdBetween?: [Date, Date], lastLoginBetween?: [Date, Date],
                          sortBy?: SortBy<'STATUS' | 'CREATEDAT' | 'LASTLOGIN'>,
                          nextToken?: string, maxResults = 20) {
    const pagination = { nextToken, maxResults };
    const filters: any = {};
    if (searchQuery) {
      filters.searchString = searchQuery;
    }
    if (accountId) {
      filters.memberOfAccountIds = [accountId];
    }
    if (createdBetween?.length === 2) {
      filters.createdAt = {
        min: createdBetween[0],
        max: createdBetween[1],
      };
    }
    if (lastLoginBetween?.length === 2) {
      filters.lastLogin = {
        min: lastLoginBetween[0],
        max: lastLoginBetween[1],
      };
    }
    if (status.length) {
      filters.status = status.map(stat => UserStatusFilter[stat]);
    }
    const sort: any = {};
    if (sortBy?.field && sortBy?.direction) {
      switch (sortBy.field) {
        case 'CREATEDAT':
          sort.createdAt = sortBy.direction;
          break;
        case 'LASTLOGIN':
          sort.lastLogin = sortBy.direction;
          break;
        case 'STATUS':
          sort.status = sortBy.direction;
          break;
      }
    }
    return this.apollo.query({
      query: usersAdminSearchQuery(accountId),
      variables: {
        filters,
        sort,
        pagination
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map(({data}: any) => data.usersAdminSearch as PaginatedResponseData<UserData & SpecificAccountScopeData>),
    );
  }

  public update(user: User): Observable<User> {
    return this.apollo.mutate<{updateUser: UserData}>({
      mutation: updateUser,
      variables: {userId: user.id, firstName: user.firstName, lastName: user.lastName}
    }).pipe(
      map(({data}) => new User(data.updateUser))
    );
  }

  public updateRoles(userId: string, accountId: string, roles: string[]): Observable<User> {
    return this.apollo.mutate<{updateUserRolesForAccount: UserData}>({
      mutation: updateUserRoles,
      variables: {userId, accountId, roles}
    }).pipe(
      map(({data}) => new User(data.updateUserRolesForAccount))
    );
  }

  public get currentAccount() {
    return {
      deviceTypes: () => {
        return this._currentUserAccount?.deviceTypes ? this._currentUserAccount.deviceTypes : [];
      },

      hasAirDevices: () => {
        return this._currentUserAccount?.deviceTypes.includes('AIR175') || this._currentUserAccount?.deviceTypes.includes('AIR20');
      },

      hasSurfaceDevices: () => {
        return this._currentUserAccount?.deviceTypes.includes('UVA10') ||
                this._currentUserAccount?.deviceTypes.includes('UVA20');
      }
    };
  }

  public updateUserPreferences(preferences: UserPreferences) {
    return this.apollo.mutate<{updateUserPreferences: UserData}>({
      mutation: updateUserPreferences,
      variables: {preferences}
    }).pipe(
      map(({data}) => new User(data.updateUserPreferences)),
    );
  }

  public updateAccount(accountId: string, attrs: {
    name: string,
    preferences: AccountPreferencesInput,
  }) {
    return this.apollo.mutate<{updateAccount: UserAccountData}>({
      mutation: updateAccountPreferences,
      variables: {
        accountId,
        attributesToUpdate: attrs
      }
    }).pipe(
      map(({data}) => data.updateAccount.id),
    );
  }
}
