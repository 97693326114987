import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal/base-modal.component';

export type UserAgreementModalResult = 'AGREE' | 'LOGOUT';

@Component({
  selector: 'uvc-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.scss']
})
export class UserAgreementModalComponent extends BaseModalComponent<UserAgreementModalResult> {

  readonly PORTAL_ACCESS_AGREEMENT_LINK = 'https://uvangel.com/portal-access-agreement/';

  title = 'Accept user agreement';

  constructor(
    protected modalRef: BsModalRef,
  ) {
    super(modalRef);
  }

  close(res) {
    super.close(res, false);
  }

}
