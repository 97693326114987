import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ScopedUserAccount } from '../service/service.model';
import { AuthService } from '../service/services/auth.service';
import { StorageService } from '../service/services/storage.service';
import { UserService } from '../service/services/user.service';

/**
 * This guard has to be used for last catch-all route in global route configuration.
 * It tries to prefix requested route with account id either
 * from local storage or from userService.
 */
@Injectable({ providedIn: 'root' })
export class RedirectingRoutingGuard implements CanActivate {

  private currentAccount: ScopedUserAccount;

  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    private storageService: StorageService,
    protected toaster: ToastrService,
    protected router: Router,
  ) {
    this.userService.currentUserAccount$().subscribe(account => this.currentAccount = account);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // I could go directly to storage to get account but local storage uses slow synchronous access.
    // It is much faster to cache current account in member attributes.
    const accountId = this.currentAccount?.id || this.storageService.getSelectedAccountId();
    if (accountId) {
      return this.router.parseUrl(`/account/${accountId}${state.url}`);
    } else {
      // Maybe there is no current account because user is not logged in
      return this.authService.isAuthenticatedOrRedirectToLogin(state.url);
    }
  }
}
