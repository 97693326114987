import { Location as NgLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ScopedUserAccount } from '../service/service.model';
import { AuthService } from '../service/services/auth.service';
import { StorageService } from '../service/services/storage.service';
import { NO_ACCOUNT, UserService } from '../service/services/user.service';
import { RoutingGuard } from './router.guard';

/**
 * This guard does everything that RoutingGuard does
 * PLUS
 * it looks on URL and compares accountId in URL
 * with current account. If there are differences then
 * it tries to load account with given id.
 * This guard resolves URL only if account is valid.
 */
@Injectable({ providedIn: 'root' })
export class AccountResolvingRoutingGuard extends RoutingGuard {

  private currentAccount: ScopedUserAccount;
  private currentRoute: ActivatedRouteSnapshot;

  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    protected toaster: ToastrService,
    protected router: Router,
    protected ngLocation: NgLocation,
    protected storageService: StorageService,
  ) {
    super(authService, userService, router);
    /**
     * This block syncs account id to URL.
     */
    userService.currentUserAccount$().pipe(
      filter(acct => acct !== NO_ACCOUNT)
    ).subscribe(acct => {
      this.currentAccount = acct;
      if (this.currentRoute?.params.accountId) {
        const oldAcctId = this.currentRoute.params.accountId;

        if (oldAcctId !== this.currentAccount.id) {
          const newUrl = this.ngLocation.path().replace(`account/${oldAcctId}`, `account/${this.currentAccount.id}`);
          this.router.navigate([newUrl], { replaceUrl: true });
        }
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentRoute = route;
    return super.canActivate(route, state).pipe(
      switchMap((loggedIn: boolean) => this.resolveAccountFromUrl(loggedIn, route, state)),
    );
  }

  /**
   * This syncs account from URL
   */
  private resolveAccountFromUrl(loggedIn: boolean, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (loggedIn) {
      let pipeline: Observable<any> = of(true);
      if (route.params.accountId !== this.currentAccount?.id) {
        pipeline = pipeline.pipe(
          switchMap(() => this.userService.getScopedAccount(route.params.accountId)),
          switchMap((account: ScopedUserAccount) => this.userService.setCurrentUserAccount(account)),
        );
      }
      return pipeline.pipe(
        map(() => true),
        catchError(error => {
          console.error(`Can not resolve account for URL: ${state.url}`);
          this.toaster.error('URL can not be accessed', 'Error');
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }
}
