 <div class="position-relative">
  <div *ngIf="!hasLocation && loadingWhenEmpty && !isError" class="location-container"><span class="fa-spin fas fa-circle-notch mr-2 text-body"></span></div>
  <div *ngIf="hasLocation || !loadingWhenEmpty; else locationReadError"
    class="location-container"
    [popover]="picker" #pickerPopover="bs-popover" placement="bottom right" containerClass="location-picker-popover" [container]="pickerContainer">
    <ol data-test-id="location-breadcrumb" class="breadcrumb" [class.deep]="isLocationDeep" #locationBreadcrumb>
      <li class="breadcrumb-item  position-relative">
        <span data-test-id="location-breadcrumb-item">{{currentLocationName}}</span>
        <a *ngIf="allowChange && locationChange.observers.length"
          href="javascript:void(0)"
          (click)="openLocationPicker($event, pickerPopover)"
          data-test-id="location-picker-btn"
          class="fas fa-map-marker-alt ml-3 d-inline"></a>
      </li>
      <ng-container *ngFor="let location of reverseLocationPath; last as isLast">
        <li class="breadcrumb-item position-relative">
          <span *ngIf="!allowChange || !locationChange.observers.length" data-test-id="location-breadcrumb-item">{{location.name}}</span>
          <a *ngIf="allowChange && locationChange.observers.length"  href="javascript:void(0)" (click)="changeLocation(location)" data-test-id="location-breadcrumb-item">{{location.name}}</a>
        </li>
      </ng-container>
    </ol>
  </div>
  <ng-template #locationReadError>
    <div *ngIf="isError" class="error-message text-danger">Error reading location</div>
  </ng-template>
  <ng-template #picker>
    <uvc-location-picker
      [preselectLocation]="isError ? null : this.location"
      [isHidden]="false"
      (isHiddenChange)="closeLocationPicker()"
      [account]="account"
      class="location-picker"
      [cancellable]="cancellable"
      (locationChange)="locationChange.emit($event)">
    </uvc-location-picker>
  </ng-template>
</div> 