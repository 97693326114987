import { FormControl } from '@angular/forms';

export class CustomValidators {

  public static notEmpty(c: FormControl) {
    return !/^\s+$/.test(c.value) ? null : {
      notEmpty: {
        valid: false
      }
    };
  }

  public static noWhitespace(c: FormControl) {
    return !c.value || /^(\S+)$/.test(c.value) ? null : {
      noWhitespace: {
        valid: false
      }
    };
  }
}
