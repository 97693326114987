import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { asyncScheduler } from 'rxjs';
import { CancellableEvent } from 'src/app/common/models/cancellable-event';
import { FullLocation, LocationWithSublocations, UserAccount } from '../../../../service/service.model';

@Component({
  selector: 'uvc-location-presenter',
  templateUrl: './location-presenter.component.html',
  styleUrls: ['./location-presenter.component.scss']
})
export class LocationPresenterComponent implements OnChanges {

  public reverseLocationPath: LocationWithSublocations[];
  public currentLocationName: string;

  @Input() loadingWhenEmpty = false;
  @Input() location: FullLocation | Error;
  @Input() allowChange = true;
  @Input() account: UserAccount;
  @Input() cancellable = false;
  // use "body" if LocationPicker popup should be attached to body. Usefull if LocationPresenter is inside
  // an element with fixed position and overflow hidden
  @Input() pickerContainer: string = undefined;
  @Output() locationChange = new EventEmitter<FullLocation | CancellableEvent<FullLocation>>();
  @Output() pickerOpen = new EventEmitter<boolean>();

  @ViewChild('locationBreadcrumb') locationBreadcrumb: ElementRef;
  @ViewChild('pickerPopover') pickerPopover: PopoverDirective;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location) {
      const location = changes.location.currentValue;
      if (location instanceof Error) {
        this.reverseLocationPath = null;
        this.currentLocationName = null;
      } else {
        this.reverseLocationPath = Object.assign([], location?.fullLocationPath).reverse();
        this.currentLocationName = location?.name;
      }
      asyncScheduler.schedule(() => this.isLocationDeep());
    }
  }

  changeLocation(location: LocationWithSublocations) {
    // We know this.location is not an error otherwise this function could not be called
    const currLocation = this.location as FullLocation;
    // index of selected location inside currLocation.fullLocationPath array
    const locationIdx = currLocation.fullLocationPath.findIndex(loc => loc.id === location.id);
    // All parent locations of selected location
    const parentLocations = locationIdx < 1 ? [] : currLocation.fullLocationPath.slice(0, locationIdx);
    // Build again FullLocation
    const fullLocation = new FullLocation({
      ...location,
      fullLocationPath: parentLocations.map(loc => new LocationWithSublocations({...loc})),
    });

    if (this.cancellable) {
      const event: CancellableEvent<FullLocation> = {
        cancel: false,
        payload: fullLocation
      };
      this.locationChange.emit(event);
    } else {
      this.locationChange.emit(fullLocation);
    }
  }

  closeLocationPicker() {
    this.pickerPopover?.hide();
    this.pickerOpen.emit(false);
  }

  get hasLocation() {
    return !!this.reverseLocationPath?.length || !!this.currentLocationName;
  }

  get isError() {
    return this.location instanceof Error;
  }

  @HostListener('window:resize', ['$event'])
  isLocationDeep() {
    if (!this.locationBreadcrumb?.nativeElement) {
      return;
    }
    const el = this.locationBreadcrumb.nativeElement;
    if (el.clientHeight > el.parentElement.clientHeight) {
      el.parentElement.classList.add('deep');
    } else {
      el.parentElement.classList.remove('deep');
    }
  }

  openLocationPicker(event: Event, popover: PopoverDirective) {
    this.pickerOpen.emit(true);
    popover.show();
    event.stopPropagation();

  }
}
