import * as moment from 'moment';

export interface IUserSessionData {
    IdToken: string;
    AccessToken: string;
    RefreshToken?: string;
}

export class UserSession {

    private idToken: string;
    private accessToken: string;
    private refreshToken: string;

    constructor(data: IUserSessionData) {
        this.idToken = data.IdToken;
        this.accessToken = data.AccessToken;
        this.refreshToken = data.RefreshToken;
    }

    public idTokenPayload(): any {
        try {
            const idTokenPieces = this.idToken.split('.');
            const idTokenJson = JSON.parse(atob(idTokenPieces[1]));
            return idTokenJson;
        }  catch (error) {
            return {};
        }
    }

    public accessTokenPayload(): any {
        try {
            const accessTokenPieces = this.idToken.split('.');
            const accessTokenJson = JSON.parse(atob(accessTokenPieces[1]));
            return accessTokenJson;
        } catch (error) {
            return {};
        }
    }

    public getAccessToken(): string  {
        return this.accessToken;
    }

    public getIdToken(): string {
        return this.idToken;
    }

    public getRefreshToken(): string {
        return this.refreshToken;
    }

    public isValid(): boolean {
        if (!this.accessToken || !this.idToken) {
            return false;
        }

        // keep buffer of 30s - we don't want to have expired token
        const BUFFER = 30000;
        const now = moment();

        // Validate Access Token First
        const parsedAccessToken = this.accessTokenPayload();
        if (!!parsedAccessToken?.exp) {
            const expirationTime = moment.unix(parsedAccessToken.exp).local();
            if (now.isBefore(expirationTime.subtract(BUFFER, 'ms'))) {
                return true;
            }
        }

        // Validate ID Token next
        const parsedIdToken = this.idTokenPayload();
        if (!!parsedIdToken?.exp) {
            const expirationTime = moment.unix(parsedIdToken.exp).local();
            if (now.isBefore(expirationTime.subtract(BUFFER, 'ms'))) {
                return true;
            }
        }

        return false;
    }
}
