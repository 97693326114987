import { version } from '../../package.json';

// new cognito instance in aws dev
const CLIENT_ID = '5ocimq4860i5jlmrcpn5ci9uu5';

export const environment = {
  version,
  env: 'dev',
  enableDevTools: true,
  enableEndpointsForE2E: true,
  aws: {
    cognito: {
      clientId: CLIENT_ID,
      loginPrefix: `https://uva-users-dev.auth.us-east-1.amazoncognito.com/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone`,
      logoutPrefix: `https://uva-users-dev.auth.us-east-1.amazoncognito.com/logout?client_id=${CLIENT_ID}&response_type=code`,
      tokenUri:
        'https://uva-users-dev.auth.us-east-1.amazoncognito.com/oauth2/token',
    },
  },
};
