import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import { SearchAccountModalComponent } from './search-account-modal/search-account-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountEditModalComponent } from './account-edit-modal/account-edit-modal.component';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { UserAgreementModalComponent } from './user-agreement-modal/user-agreement-modal.component';


@NgModule({
  declarations: [
      ConfirmationModalComponent,
      SearchAccountModalComponent,
      AccountEditModalComponent,
      BaseModalComponent,
      UserAgreementModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
      BaseModalComponent,
      ConfirmationModalComponent,
      AccountEditModalComponent,
      UserAgreementModalComponent,
      SearchAccountModalComponent
  ]
})
export class ModalModule { }
