import { DOCUMENT, Location as NgLocation } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, PRIMARY_OUTLET, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { combineLatest, fromEvent, merge, of } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AbstractComponent } from './common/components';
import { AUTH_CALLBACK_PATH } from './routing/routing.module';
import { UserAccount } from './service/service.model';
import { AuthService } from './service/services/auth.service';
import { StorageService } from './service/services/storage.service';
import { ThemeService } from './service/services/theme.service';
import { NO_ACCOUNT, PORTAL_ACCESS_AGREEMENT, PORTAL_ACCESS_AGREEMENT_CURR_VERSION, UserService } from './service/services/user.service';
import { SearchAccountModalComponent } from './widget/modal/search-account-modal/search-account-modal.component';
import { UserAgreementModalComponent } from './widget/modal/user-agreement-modal/user-agreement-modal.component';

const SEPARATOR = `·`;
const pageTitleSuffix = `UV Angel Cloud`;
// These will be read from user configuration on backend when ready
const AUTO_LOGOUT_TIMEOUT = 30 * 60 * 1000; // 30min in milliseconds
// How long is auto-logout warning shown before actual log out occurs
const AUTO_LOGOUT_WARNING_TIMEOUT = 2 * 60 * 1000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('noAccountMessage', {static: false}) noAccountMessage: TemplateRef<any>;
  @ViewChild('main', {static: false}) mainElement: ElementRef;

  lazyLoading = false;
  focusListenerInstalled = false;
  currentPageIsPublic = false;
  subheaderShown = false;
  currentAccount: UserAccount;

  private autologoutHandle: number | undefined;
  private warningHandle: number | undefined;
  private warningToast: ActiveToast<any>;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private storageService: StorageService,
    private ngLocation: NgLocation,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document) {
      super();
      moment.updateLocale('en', {
        relativeTime : {
          h: '1 hour',
          d: '1 day',
          M: '1 month',
          y: '1 year'
        }
      });

      this.setSelectedAccountByUrl();

      this.router.events.subscribe(event => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.lazyLoading = true;
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.lazyLoading = false;
            break;
          }
          default: {
            break;
          }
        }
      });

      this.installAutoLogoutHandler();
    }

  ngAfterViewInit() {
    this.promptUserAgreement();
    combineLatest([
      this.userService.currentUserAccount$(),
      this.themeService.currentTheme()
    ]).pipe(
      switchMap(([account, _]) => {
        /**
         * This is special handling of a situation when the user doesn't have any "home" account and he wasn't
         * logged before i.e. previous account id was not stored in local storage.
         * In that case we shouw a dialog for user to choose which account he wants to use.
         */
        if (account === NO_ACCOUNT) {
          const modalRef = this.modalService.show(SearchAccountModalComponent, {
            initialState: {showClose: false}
          });
          return modalRef.content.result.pipe(
            // load full account details
            switchMap(selectedAccount => this.userService.getScopedAccount(selectedAccount.id)),
            // set it as current -> will send another value from the source observable of this chain
            switchMap(fullAccount => this.userService.setCurrentUserAccount(fullAccount)),
            // will pass NO_ACCOUNT along and will be stopped by filter() operator that is next
            map(() => account),
            tap(() => modalRef.hide()),
          );
        } else {
          return of(account);
        }
      }),
      filter(account => account !== NO_ACCOUNT)
    ).subscribe(
      // all is good - user has account
      account => {
        this.currentAccount = account;
        // Hide initial login/loading screen
        if (this.authService.isAuthenticated()) {
          this.hideLoader();
        }
      },
      // User doesn't have any account assigned.
      err => {
        this.hideLoader();
        this.modalService.show(this.noAccountMessage);
      }
    );
  }

  /**
   * Display a prompt asking user to prompt user agreement if he hasn't done so yet.
   */
  promptUserAgreement() {
    let modalRef: BsModalRef = null;
    this.userService.currentUser$().pipe(
      first(),
      filter(user => !user.agreements.some(agreement => agreement.type === PORTAL_ACCESS_AGREEMENT &&
        agreement.version === PORTAL_ACCESS_AGREEMENT_CURR_VERSION)),
      switchMap(() => {
        modalRef = this.modalService.show(UserAgreementModalComponent);
        return modalRef.content.result;
      }),
      switchMap(result => {
        if (result === 'AGREE') {
          return this.userService.signAgreement(PORTAL_ACCESS_AGREEMENT, PORTAL_ACCESS_AGREEMENT_CURR_VERSION).pipe(
            map(() => result),
          );
        } else {
          return of(result);
        }
      }),
    ).subscribe(
      result => {
        if (result === 'LOGOUT') {
          this.logout();
        } else if (result === 'AGREE') {
          this.toastr.success('User agreement signed', 'Success');
        }
        modalRef.hide();
      },
      error => this.toastr.error('Error signing user agreement', 'Error').onHidden.subscribe(() => this.logout())
    );
  }

  /**
   * Hide the loader that is shows while Angular application is loading
   */
  hideLoader() {
    document.body.classList.remove('overlay-open');
  }

  ngOnInit() {
    // pull the default title from index.html via titleService.getTitle();
    // pull indication of public page
    const appTitle = this.titleService.getTitle();
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          // iterate over child routes to get last route in tree
          while (child.firstChild) {
            child = child.firstChild;
          }
          this.currentPageIsPublic = !!child.snapshot.data.isPublic;
          // Hide initial login/loading screen
          if (this.currentPageIsPublic) {
            this.hideLoader();
          }
          if (child.snapshot.data.title) {
            if (this.currentAccount) {
              return `${child.snapshot.data.title} ${SEPARATOR} ${this.currentAccount.name} ${SEPARATOR} ${pageTitleSuffix}`;
            } else {
              return `${child.snapshot.data.title} ${SEPARATOR} ${pageTitleSuffix}`;
            }
          }
          // no custom title found - returning default title
          return appTitle;
        }),
        tap(() => {
          if (!this.focusListenerInstalled) {
            // This redirects user to login page after initial page load.
            // But prevents never ending loop when comming back from login page.
            if (!this.currentPageIsPublic &&
              !(this.ngLocation.path().includes(AUTH_CALLBACK_PATH) ||
              // e2e paths afe used for testing login process and should be excluded from this
              this.ngLocation.path().includes('/e2e/'))) {
              this.authService.isAuthenticatedOrRedirectToLogin(this.ngLocation.path()).subscribe(
                // This listener must be installed only after we attempt login to prevent
                // duplicate call of /oauth2/token endpoint
                () => this.installWindowOnFocusListener()
              );
            } else {
              this.installWindowOnFocusListener();
            }
          }
        }),
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  logout() {
    this.authService.logout(this.router.url);
  }

 /**
  * This code works in tandem with AccountResolvingRoutingGuard and RedirectingRoutingGuard
  * to maintain account in sync with URL.
  * This particular code executes on very first rendering and it detects if account
  * is in the URL. If it is there then it will be set as previously selected account.
  * This will then trigger loading of this account/location after successful authentication.
  */
  setSelectedAccountByUrl() {
       const urlTree = this.router.parseUrl(this.ngLocation.path());
       const rootGroup = urlTree.root.children[PRIMARY_OUTLET];
       // only do this if the url actually contains account id
       if (rootGroup?.segments?.length > 1 && 'account' === rootGroup.segments[0].path) {
         const accountId = rootGroup.segments[1].path;
         this.storageService.setSelectedAccountId(accountId);
       }
  }

  @HostListener('click', ['$event.target'])
  onClick(target: Element) {
    if (target.classList.contains('main') && this.document.body.classList.contains('sidebar-show')) {
      this.document.body.classList.remove('sidebar-show');
    }
  }

  /**
   * Installs listener for window.focus events.
   * When that event occurs and user is not logged in (e.g. has expired token)
   * then it tires to log user in again.
   */
  installWindowOnFocusListener() {
    if (!this.focusListenerInstalled) {
      fromEvent(window, 'focus').pipe(
        filter(() => !this.authService.isAuthenticated() && !this.currentPageIsPublic),
        switchMap(() => this.authService.refreshTokenOrRedirectToLogin()),
        takeUntil(this.destroyed$),
      ).subscribe();
      this.focusListenerInstalled = true;
    }
  }

  private installAutoLogoutHandler() {
    merge(
      fromEvent(this.document, 'mousemove'),
      fromEvent(this.document, 'mousedown'),
      fromEvent(this.document, 'touchstart'),
      fromEvent(this.document, 'click'),
      fromEvent(this.document, 'orientationchange'),
      fromEvent(this.document, 'keydown'),
      fromEvent(this.document, 'wheel'),
      fromEvent(this.document, 'scroll', {capture: true}),
    ).subscribe(e => {
      this.resetAutologoutTimer();
    });
    this.resetAutologoutTimer();
  }

  private resetAutologoutTimer() {
    if (this.autologoutHandle) {
      clearTimeout(this.autologoutHandle);
    }
    if (this.warningHandle) {
      clearTimeout(this.warningHandle);
    }
    if (this.warningToast) {
      this.toastr.remove(this.warningToast.toastId);
      this.warningToast = null;
    }
    this.warningHandle = window.setTimeout(() => {
      this.warningToast = this.toastr.info('You will be soon automatically logged out due to inactivity', 'Auto logout', {
        timeOut: AUTO_LOGOUT_WARNING_TIMEOUT,
        progressBar: true,
      });
    }, AUTO_LOGOUT_TIMEOUT - AUTO_LOGOUT_WARNING_TIMEOUT);
    this.autologoutHandle = window.setTimeout(() => this.logout(), AUTO_LOGOUT_TIMEOUT);
  }

  handleSubheaderShown(shown: boolean) {
    this.subheaderShown = shown;
  }
}
