<uvc-base-modal [footer]="footer" [title]="title">
    <div [formGroup]="accountForm">
        <div class="form-group">
            <label for="accountName" i18n="@@account-create-modal_name-label">Name</label>
            <input type="text" id="accountName" data-test-id="create-account_name-input" class="form-control" formControlName="name" required 
                [ngClass]="{'is-valid': accountName.valid && (accountName.dirty || accountName.touched), 'is-invalid': accountName.invalid && (accountName.dirty || accountName.touched)}"/>
            <div *ngIf="accountName.invalid && (accountName.dirty || accountName.touched)" class="invalid-feedback form-text">
                <div i18n="@@account-create-modal_field-error-name" data-test-id="create-account_name-required-msg" *ngIf="accountName.errors?.required || accountName.errors?.notEmpty">Name is required</div>
            </div>
        </div>

        <div class="form-group">
            <label i18n="@@account-create-modal_account-profile-label">Account Profile</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" id="locationType-office" data-test-id="create-account_type-office" value="office" checked formControlName="locationTypes" class="form-check-input">
                    <label class="form-check-label" for="locationType-office" i18n="@@account-create-modal_type-office">Office</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" id="locationType-campus" data-test-id="create-account_type-campus" value="campus" formControlName="locationTypes" class="form-check-input">
                    <label class="form-check-label" for="locationType-campus" i18n="@@account-create-modal_type-campus">Campus</label>
                </div>
            </div>
        </div>
    </div>
</uvc-base-modal>

<ng-template #footer>
    <div class="d-flex w-100 justify-content-between">
        <button data-test-id="create-account_cancel-btn" type="button" class="btn btn-secondary" (click)="doCancel()" [disabled]="saving" i18n="@@account-create-modal_cancel-btn">Cancel</button>
        <button data-test-id="create-account_create-btn" type="button" class="btn btn-primary" (click)="doSave()" [disabled]="accountForm.invalid || saving">
            <ng-container *ngIf="!saving" i18n="@@account-create-modal_create-btn">Save</ng-container>
            <span *ngIf="saving" class="fa-spin fas fa-circle-notch mr-2"></span>
        </button>
    </div>
</ng-template>
