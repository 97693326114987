import {Inject, Injectable, InjectionToken, OnDestroy, Optional} from '@angular/core';
import {UserSession} from './user-session.service';
import {ReplaySubject, Observable} from 'rxjs';

const ID_TOKEN_KEY = 'auth.idToken';
const ACCESS_TOKEN_KEY = 'auth.accessToken';
const REFRESH_TOKEN_KEY = 'auth.refreshToken';
const SELECTED_ACCOUNT = 'account';
const SELECTED_LOCATION_PREFIX = 'location';
const SELECTED_THEME = 'theme';
const POLLING_INTERVAL = 'polling-interval';
const SELECTED_UTC_OFFSET = 'utc-offset';
const PREVIOUS_URL = 'previousUrl';
const LAST_USED_ACCOUNTS = 'last-used-accounts';

export const LAST_USED_ACCOUNTS_LENGTH = new InjectionToken<number>('lastUsedAccountsLength');

/**
 * This service is the storage layer for the AuthService. It stores into and recovers tokens from local storage,
 * knows how to serve the api token, and how to get the user roles from the token.
 */
@Injectable({
    providedIn: 'root'
})
export class StorageService implements OnDestroy {
    private sessionSubject = new ReplaySubject<UserSession>(1);
    private utcOffset: string;
    private currUserId: string;

    public constructor(@Inject(LAST_USED_ACCOUNTS_LENGTH)@Optional() public lastUsedAccountsLength: number = 5) {
        if (lastUsedAccountsLength === null || lastUsedAccountsLength < 0) {
            this.lastUsedAccountsLength = 0;
        }
    }

    /**
     * Sets current user id. This id is subsequently used to prefix all stored/read keys in local storage.
     */
    public setCurrUserId(id: string) {
        this.currUserId = id;
    }

    public ngOnDestroy() {
        this.sessionSubject.complete();
    }

    public getCognitoUserSession(): UserSession {
        return new UserSession({
            IdToken: localStorage.getItem(ID_TOKEN_KEY) || '',
            AccessToken: localStorage.getItem(ACCESS_TOKEN_KEY) || '',
            RefreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) || ''
        });
    }

    public getApiToken(): string {
        return this.getCognitoUserSession().getAccessToken();
    }

    public getSelectedAccountId(): string | null {
        return localStorage.getItem(SELECTED_ACCOUNT);
    }

    public getSelectedLocationId(accountId: string): string | null {
        return localStorage.getItem(`${SELECTED_LOCATION_PREFIX}_${accountId}`);
    }

    public getSelectedTheme(): string | null {
        return localStorage.getItem(`${SELECTED_THEME}`);
    }

    public getPollingIntervalSeconds(): number {
        return parseInt(localStorage.getItem(POLLING_INTERVAL), 10) || 10;
    }

    public getSelectedUtcOffset(): string {
        let offset = this.utcOffset || localStorage.getItem(SELECTED_UTC_OFFSET);
        if (!offset) {
            let offsetInMinutes = new Date().getTimezoneOffset();
            // Negative minutes mean timezone is ahead of UTC
            offset = offsetInMinutes > 0 ? '-' : '+';
            offsetInMinutes = Math.abs(offsetInMinutes);
            offset += `${('0' + Math.floor(offsetInMinutes / 60)).slice(-2)}:${('0' + offsetInMinutes % 60).slice(-2)}`;
            this.utcOffset = offset;
            this.setSelectedUtcOffset(offset);
        }
        return offset;
    }

    /**
     * Add Account to Last Selected Accounts list
     * Read last used accounts. If the current account is in it then bump it to first position.
     * If it is not there then add it to first place.
     */
    public addAccountToLSA(accountName: string, accountId: string) {
        const lua = this.getLastUsedAccounts();
        const idInsideLua = lua.findIndex(item => item.accountId === accountId);
        if (idInsideLua === 0) {
            // nothing to do here: account is already in lua and is on 1st place
            return;
        } else if (idInsideLua > 0) {
            // account is already in lua but not in first place - remove it (for now)
            lua.splice(idInsideLua, 1);
        }

        lua.unshift({accountName, accountId});
        if (lua.length > this.lastUsedAccountsLength) {
            // trim extra accounts, keep only predefined number
            lua.splice(this.lastUsedAccountsLength, lua.length - this.lastUsedAccountsLength);
        }
        this.storeOrRemove(this.currUserId + '_' + LAST_USED_ACCOUNTS, JSON.stringify(lua));
    }

    public currentSession(): Observable<UserSession> {
        return this.sessionSubject.asObservable();
    }

    public setCognitoUserSession(idToken?: string, accessToken?: string, refreshToken?: string) {
        console.debug('Storing new tokens!');
        this.storeOrRemove(ID_TOKEN_KEY, idToken);
        this.storeOrRemove(ACCESS_TOKEN_KEY, accessToken);
        this.storeOrRemove(REFRESH_TOKEN_KEY, refreshToken);
        if (idToken || accessToken || refreshToken) {
            this.sessionSubject.next(this.getCognitoUserSession());
        }
    }

    public setSelectedAccountId(id: string) {
        this.storeOrRemove(SELECTED_ACCOUNT, id);
    }

    public setSelectedLocationId(id: string, accountId: string) {
        this.storeOrRemove(`${SELECTED_LOCATION_PREFIX}_${accountId}`, id);
    }

    public setPollingIntervalSeconds(pollingIntervalSeconds: number): void {
        this.storeOrRemove(POLLING_INTERVAL, pollingIntervalSeconds + '');
    }

    public setSelectedTheme(theme: string) {
        this.storeOrRemove(SELECTED_THEME, theme);
    }

    public setSelectedUtcOffset(offset: string) {
        this.utcOffset = offset;
        this.storeOrRemove(SELECTED_UTC_OFFSET, offset);
    }

    public setPreviousUrl(url: string) {
        this.storeOrRemove(PREVIOUS_URL, url);
    }

    public getPreviousUrl() {
        localStorage.getItem(PREVIOUS_URL);
    }

    public getLastUsedAccounts(): {accountId: string; accountName: string}[] {
        const luaString = localStorage.getItem(this.currUserId + '_' + LAST_USED_ACCOUNTS);
        if (luaString) {
            try {
                return JSON.parse(luaString);
            } catch (error) {
                return [];
            }
        } else {
            return [];
        }
    }

    private storeOrRemove(key: string, value?: string) {
        if (typeof value === 'string' && value.length > 0) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
}
