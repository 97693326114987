<div class="app-body">
  <uvc-sidebar *uvcIsAuthenticated [class.header-with-subheader]="subheaderShown"></uvc-sidebar>
  <div class="main uvangel-main" [class.header-with-subheader]="subheaderShown">
    <uvc-header (subheaderShown)="handleSubheaderShown($event)"></uvc-header>
    <div *ngIf="lazyLoading" class="lazy-loading"></div>
    <router-outlet *ngIf="!lazyLoading"></router-outlet>
    <footer class="container-fluid d-flex justify-content-between align-items-center flex-row pt-4">
      <!-- TODO: allow for custom image once its pulled from our own S3 bucket for each account -->
      <!-- <img *ngIf="userService.isAuthenticated()" src="assets/LimaMemorial.png" width="200px" /> -->
      <small>&copy; 2023 UV Angel. All Rights Reserved. <a target="_blank" href="https://uvangel.com/contact" i18n="@@app_contact-link">Contact Us</a></small>
    </footer>
  </div>
  <hr />
</div>

<ng-template #noAccountMessage>
  <div class="content">
    <p i18n="@@app_user-not-added-to-account">Your user has not been added to any account.</p>
    <p i18n="@@app_discuss-with-manager">Please discuss your UVAngel permissions with your manager or account admin.</p>
    <p i18n="@@app_logout-link"><a href="javascript:void(0)" (click)="logout()" >Click here</a> to log out.</p>
  </div>
</ng-template>
