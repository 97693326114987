<uvc-base-modal [title]="title || 'Select Account'" [showClose]="showClose" i18n-title="@@search-account-modal_title" #parent>
    <div class="search-box mb-2">
        <label for="accountTypeahead" class="font-weight-bold" i18n="@@search-account-modal_typeahead-label">Enter searched text</label>
        <input id="accountTypeahead" [formControl]="typeaheadInput" data-test-id="search-account-typeahead" class="form-control">
    </div>
    <div *ngIf="showLastUsedAccounts && !typeaheadInput.value" [@growNShrink] class="mb-4">
        <div class="font-weight-bold" i18n="@@search-account-modal-last_used_accounts">Recently Used Accounts</div>
        <div *ngFor="let item of lua" class="account-row highlight-row m-2">
            <div class="account-name">{{item.accountName}}</div>
            <div class="account-users"></div>
            <div class="account-locations"></div>
            <div class="account-action">
                <button *ngIf="!doNotSelect?.includes(item.accountId)"
                    class="btn btn-secondary btn-sm"
                    (click)="emitSelectAccount(item)"
                    data-test-id="select-lua-link"
                    [disabled]="loadingAccount"
                    i18n="@@search-account_select-link">
                    <ng-container *ngIf="!loadingAccount || lastSelectedId !== item.accountId">Select</ng-container>
                    <span *ngIf="loadingAccount && lastSelectedId === item.accountId" class="fa-spin fas fa-circle-notch"></span>
                </button>
                <span *ngIf="doNotSelect?.includes(item.accountId)" class="fas fa-check text-success" data-test-id="account-checkmark"></span>
            </div>
        </div>
    </div>
    <div *ngIf="typeaheadInput.value && pagedResults?.items?.length > 0"
        class="account-count"
        data-test-id="search-account-count"
        i18n="@@search-account-modal-account_count">
        {{ pagedResults.totalCount }} account<ng-container *ngIf="pagedResults.totalCount > 1">s</ng-container> match<ng-container *ngIf="pagedResults.totalCount === 1">es</ng-container> your search criteria
    </div>
    <div *ngIf="typeaheadInput.value && pagedResults?.items?.length === 0"
        class="account-count"
        data-test-id="search-account-count"
        i18n="@@search-account-modal-account_count">
        No accounts found
    </div>
    <div *ngIf="loading && !pagedResults" class="loading-container">
        <span class="fas fa-spin fa-circle-notch"></span>
    </div>
    <div *ngIf="pagedResults?.items?.length > 0 && !beError" class="search-results mt-4">
        <div class="account-row">
            <div class="account-name font-weight-bold" i18n="@@search-account-modal-account_name">Account Name</div>
            <div class="account-users font-weight-bold icon-user" i18n="@@search-account-modal-account_members"></div>
            <div class="account-locations font-weight-bold icon-directions" i18n="@@search-account-modal-account_locations"></div>
            <div class="account-action"></div>
        </div>
        <div *ngFor="let item of pagedResults.items" class="account-row highlight-row m-2">
            <div class="account-name">{{item.name}}</div>
            <div class="account-users">{{item.numMembers}}</div>
            <div class="account-locations">{{item.numLocations}}</div>
            <div class="account-action">
                <button *ngIf="!doNotSelect?.includes(item.id)"
                    class="btn btn-secondary btn-sm"
                    (click)="emitSelectAccount(item)"
                    data-test-id="select-account-link"
                    [disabled]="loadingAccount"
                    i18n="@@search-account_select-link">
                    <ng-container *ngIf="!loadingAccount || lastSelectedId !== item.id">Select</ng-container>
                    <span *ngIf="loadingAccount && lastSelectedId === item.id" class="fa-spin fas fa-circle-notch"></span>
                </button>
                <span *ngIf="doNotSelect?.includes(item.id)" class="fas fa-check text-success" data-test-id="account-checkmark"></span>
            </div>
        </div>
    </div>
    <div *ngIf="beError" class="loading-error">
        <div class="error-message text-danger" data-test-id="error-title" i18n="@@search-account_loading-error">An error occurred.</div>
        <a href="javascript:void(0)" (click)="loadNextPage()" data-test-id="retry-link" i18n="@@search-account_retry-link" class="text-body"><small>Retry</small></a>
    </div>
    <div class="show-more mt-4" *ngIf="pagedResults?.items?.length > 0">
        <div *ngIf="loading" class="fa-spin fas fa-circle-notch" data-test-id="show-more-loading-icon"></div>
        <a *ngIf="!loading && pagedResults.items.length < pagedResults.totalCount"
            href="javascript:void(0)"
            (click)="loadNextPage()"
            data-test-id="search-account-modal-show-more"
            i18n="@@search-account-modal_show-more">
                Show next {{ pagedResults.totalCount - pagedResults.items.length > PAGE_SIZE ? PAGE_SIZE : pagedResults.totalCount - pagedResults.items.length }} accounts <span *ngIf="!!pagedResults.totalCount">
                (of {{pagedResults.totalCount - pagedResults.items.length }} left)</span>
        </a>
    </div>
</uvc-base-modal>