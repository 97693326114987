import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {StorageService} from './storage.service';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';


const REDIRECT_URI_LOGIN = `${document.location.origin}/callback/authenticated`;
const LOGOUT_URI = `${document.location.origin}/logout`;

/**
 * This service handles all authentication related stuff. The Amazon Cognito specific logic is not supposed to
 * ever leave this service.
 * Furthermore, this service serves as a layer on top of the StorageService. The rest of the application is never meant to
 * talk directly to the StorageService; that's why you see some proxying going on (for example: the api token).
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private router: Router,
        private http: HttpClient,
        @Inject(DOCUMENT)
        private document: Document,
        private storageService: StorageService) {}

    // DO NOT RENAME OR REMOVE THIS CONSTANT; E2E TESTS RELY ON IT
    private cognitoLoginUrl = `${environment.aws.cognito.loginPrefix}&redirect_uri=${REDIRECT_URI_LOGIN}`;

    // DO NOT RENAME OR REMOVE THIS CONSTANT; E2E TESTS RELY ON IT
    private cognitoLogoutUrl = `${environment.aws.cognito.logoutPrefix}&redirect_uri=${REDIRECT_URI_LOGIN}&logout_uri=${LOGOUT_URI}`;

    public isAuthenticated(): boolean {
        const tokenIsValid = this.storageService.getCognitoUserSession().isValid();
        return tokenIsValid;
    }

    public isAuthenticatedOrRedirectToLogin(redirectRoute?: string): Observable<boolean> {
        if (this.isAuthenticated()) {
            return of(true);
        } else {
            return this.refreshTokenOrRedirectToLogin(redirectRoute);
        }
    }

    public refreshTokenOrRedirectToLogin(redirectRoute?: string): Observable<boolean> {
        return this.reloadTokens().pipe(tap(authenticated => {
            if (!authenticated) {
                console.debug('[Auth] Redirecting to login page');
                this.storageService.setCognitoUserSession(null, null, null);
                // Cognito will append state parameter to redirected_uri on successful login
                const cognitoFullUrl = this.cognitoLoginUrl + `&state=${btoa(redirectRoute || this.router.url)}`;
                this.document.location.href = cognitoFullUrl;
            }
        }));
    }

    public getApiToken(): string {
        return this.storageService.getApiToken();
    }

    public processAuthCode(authCode: string): Observable<boolean> {
        console.debug('[Auth] Processing auth code ' + authCode);
        const httpParams = new HttpParams()
            .set('client_id', environment.aws.cognito.clientId)
            .set('grant_type', 'authorization_code')
            .set('code', authCode)
            .set('redirect_uri', REDIRECT_URI_LOGIN);
        return this.getAndCacheTokens(httpParams);
    }

    public logout(prevUrl?: string) {
        console.debug('[Auth] Logging out');
        this.storageService.setCognitoUserSession(null, null, null);
        this.storageService.setPreviousUrl(prevUrl);
        this.document.location.href = this.cognitoLogoutUrl;
    }

    private reloadTokens(): Observable<boolean> {
        console.debug('[Auth] Reloading tokens using refresh token');
        const refreshToken = this.storageService.getCognitoUserSession().getRefreshToken();
        if (typeof (refreshToken) !== 'string' || refreshToken.trim().length === 0) {
            return of(false);
        }
        const postParams = new HttpParams()
            .set('client_id', environment.aws.cognito.clientId)
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken);
        return this.getAndCacheTokens(postParams, refreshToken);
    }

    private getAndCacheTokens(postParams: HttpParams, refreshToken?: string): Observable<boolean> {
        return this.http
            .post(environment.aws.cognito.tokenUri, postParams)
            .pipe(
                tap((resp: any) => this.storageService.setCognitoUserSession(
                    resp.id_token,
                    resp.access_token,
                    resp.refresh_token || refreshToken)
                ),
                map(() => this.isAuthenticated()),
                catchError(e => {
                    console.error(e);
                    return of(false);
                })
            );
    }
}
