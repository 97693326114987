<div class="form-group">
    <div class="selected-account" (click)="openAccountSearchModal()">
        <span *ngIf="selectedAccount" class="selected-account-name">{{selectedAccount.name}}</span>
        <span *ngIf="!selectedAccount" class="selected-account-name-none" i18n="@@account-picker_account-none">No account selected</span>
        <span
            class="fas fa-pencil-alt ml-2 text-muted"
            data-test-id="account-picker-select-account"
            i18n="@@account-picker_select-account"></span>
    </div>
    <div *ngIf="includeAllAccounts" class="d-block">
        <input type="checkbox" class="mr-2" id="select-all-accounts" [formControl]="selectAllAccounts" data-test-id="account-picker-select-all-accounts">
        <label for="select-all-accounts" class="select-all-label" i18n="@@account-picker_select-all">Select all accounts</label>
    </div>
</div>
