<ng-container>
    <div class="modal-header"><ng-container *ngTemplateOutlet="header ||  defaultHeader"></ng-container></div>
    <div class="modal-body"><ng-content></ng-content></div>
    <div *ngIf="footer" class="modal-footer"><ng-container *ngTemplateOutlet="footer"></ng-container></div>
</ng-container>
  
<ng-template #defaultHeader>
    <h4 class="modal-title">{{ title }}</h4>
    <button *ngIf="showClose" aria-label="Close" class="close text-body" type="button" (click)="dismiss()" data-test-id="dismiss-modal-btn">
        <span aria-hidden="true">×</span>
    </button>
</ng-template>
  