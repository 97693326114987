import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isObject } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AccountDatasourceType, UserRole } from '../service/service.model';
import { AuthService } from '../service/services/auth.service';
import { UserService } from '../service/services/user.service';

export interface RoleRouteData {
  role: UserRole;
  type: 'GLOBAL' | 'BOTH';
}

@Injectable({ providedIn: 'root' })
export class RoutingGuard implements CanActivate {

  constructor(protected authService: AuthService, protected userService: UserService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isLoggedInWithRoleAndDataSource(route, state);
  }

  protected isLoggedInWithRoleAndDataSource(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticatedOrRedirectToLogin(state.url).pipe(
      switchMap(loggedIn =>  {
        return this.userAccountHasRoleAndDataSource(route.data?.allowedRoles, route.data?.datasource).pipe(
          map(hasRoleDatasource => {
            if (loggedIn && !hasRoleDatasource) {
              // only redirect if properly logged, to avioid redirect loops
              this.router.navigate(['/']);
            }
            return loggedIn && hasRoleDatasource;
          })
        );
      })
    );
  }

  private userAccountHasRoleAndDataSource(allowedRoles: (UserRole | RoleRouteData)[],
                                          datasource: AccountDatasourceType): Observable<boolean> {
    return this.userService.currentUserAccount$().pipe(
        map(account => {
          let hasRoles = true;
          if (allowedRoles?.length) {
            // allowedRoles is an array that contains either a role with specification of its type (global or both)
            // or directly a role of type both(default). Both means user can have either global or account specific role.
            const globalRoles = allowedRoles.filter(item => isObject(item) && item.type === 'GLOBAL')
                                            .map(item => isObject(item) ? item.role : item);
            const acctRoles = allowedRoles.filter(item => !isObject(item) || item.type === 'BOTH')
                                          .map(item => isObject(item) ? item.role : item);
            hasRoles = globalRoles.length && this.userService.currentUserHasAnyRole(globalRoles, 'GLOBAL') ||
                        acctRoles.length && this.userService.currentUserHasAnyRole(acctRoles);
          }
          const hasDatasource = !datasource || account.hasDatasource(datasource);
          return hasRoles && hasDatasource;
        })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard implements CanActivate {

  constructor(
      private userService: UserService,
      private authService: AuthService,
      private router: Router,
      private toastrService: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
    return this.authService.processAuthCode(route.queryParamMap.get('code')).pipe(
      switchMap(success => {
        if (success) {
          return this.userService.currentUserAccount$().pipe(
            take(1),
            map(() => {
              const originalUrlBase64 = route.queryParamMap.get('state');
              const url = originalUrlBase64 ? atob(originalUrlBase64) : '/dashboard';
              return this.router.parseUrl(url);
            }),
          );
        } else {
          this.toastrService.error('Could not login to your account. Please try again.', 'Login failed');
          return of(false);
        }
      })
    );
  }
}
