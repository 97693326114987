<app-sidebar [fixed]="true" [display]="'lg'">
  <div class="sidebar-brand d-none d-lg-flex">
    <uvc-logo></uvc-logo>
  </div>
  
  <nav class="sidebar-nav" role="nav">
    <div class="nav">
      <div class="nav-item">
        <a class="nav-link" routerLink="/dashboard" uvcRouterLinkActive="active" data-test-id="sidebar_link-dashboard" appMobileSidebarToggler>
          <i class="nav-icon icon-speedometer"></i> <ng-container i18n="@@sidebar_dashboard-link">Dashboard</ng-container>
        </a>
      </div>
      <div class="nav-item" *ngIf="userService.currentUserHasRole(UserRole.DEVICE_READ)">
        <a class="nav-link" uvcRouterLinkActive="active" routerLink="/devices" data-test-id="sidebar_link-devices" appMobileSidebarToggler>
          <i class="nav-icon icon-layers"></i> <ng-container i18n="@@sidebar_devices-link">Devices</ng-container>
        </a>
      </div>
      <div *ngIf="userService.currentUserHasRole(UserRole.REPORT_READ) && (userService.currentAccount.hasAirDevices() || userService.currentAccount.hasSurfaceDevices())"
        class="nav-item nav-dropdown" #reportsNav #navDropdown>
        <a class="nav-link nav-dropdown-toggle" (click)="toggleNavDropdown(reportsNav)" data-test-id="sidebar_reports-group">
          <i class="nav-icon icon-graph"></i> <ng-container i18n="@@sidebar_reports">Reports</ng-container>
        </a>
        <div class="nav-dropdown-items">
          <div class="nav-item" *ngIf="userService.currentAccount.hasSurfaceDevices()">
            <a class="nav-link" uvcRouterLinkActive="active" routerLink="/reports/surface" data-test-id="sidebar_link-surface" appMobileSidebarToggler>
              <i class="nav-icon icon-target"></i> <ng-container i18n="@@sidebar_reports-surface">Surface</ng-container>
            </a>
          </div>
          <div class="nav-item" *ngIf="userService.currentAccount.hasAirDevices()">
            <a class="nav-link" uvcRouterLinkActive="active" routerLink="/reports/air" data-test-id="sidebar_link-air" appMobileSidebarToggler>
              <i class="nav-icon fas fa-fan"></i> <ng-container i18n="@@sidebar_reports-air">Air</ng-container>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4 nav-title" *ngIf="account?.datasources?.length > 0" i18n="@@sidebar_data-partners">UV DATA PARTNERS</div>
      <div class="nav-item" *ngIf="account?.hasDatasource('SURFACIDE')" data-test-id="partner-surfacide-menu">
          <a class="nav-link" uvcRouterLinkActive="active" routerLink="/partner/surfacide" appMobileSidebarToggler>
              <i class="nav-icon icon-star"></i> Surfacide
          </a>
      </div>
      <div class="nav-item" *ngIf="account?.hasDatasource('OPTISOLVE')" data-test-id="partner-optisolve-menu">
        <a class="nav-link" uvcRouterLinkActive="active" routerLink="/partner/optisolve" appMobileSidebarToggler>
          <i class="nav-icon icon-star"></i> Optisolve
        </a>
      </div>
      <div class="nav-item" *ngIf="account?.hasDatasource('CENTRAK')" data-test-id="partner-centrak-menu">
        <a class="nav-link" uvcRouterLinkActive="active" routerLink="/partner/skytron-centrak" appMobileSidebarToggler>
          <i class="nav-icon icon-star"></i> Skytron/Centrak
        </a>
      </div>
      <div class="nav-item" *ngIf="account?.hasDatasource('ZAN')" data-test-id="partner-zan-menu">
        <a class="nav-link" uvcRouterLinkActive="active" routerLink="/partner/zan" appMobileSidebarToggler>
          <i class="nav-icon icon-star"></i> Zan Compute
        </a>
      </div>
      <div *ngIf="userService.currentUserHasAnyRole([UserRole.LOCATION_WRITE, UserRole.USERS_WRITE]) || userService.currentUserHasRole(UserRole.ACCOUNT_WRITE, 'GLOBAL')"
        class="nav-item nav-dropdown" #adminNav #navDropdown>
        <a class="nav-link nav-dropdown-toggle" data-test-id="admin-menu" (click)="toggleNavDropdown(adminNav)">
          <i class="nav-icon far fa-address-card"></i><ng-container i18n="@@sidebar_account-admin">Admin</ng-container>
        </a>
        <div class="nav-dropdown-items">
          <div class="nav-item" *ngIf="userService.currentUserHasRole(UserRole.LOCATION_WRITE)">
            <a class="nav-link" uvcRouterLinkActive="active" routerLink="/admin/locations/edit" data-test-id="sidebar-locations" appMobileSidebarToggler>
              <i class="nav-icon icon-directions"></i> <ng-container>Locations</ng-container>
            </a>
          </div>
          <div class="nav-item" *ngIf="userService.currentUserHasRole(UserRole.USERS_WRITE)">
            <a class="nav-link" data-test-id="sidebar_admin-users_link" uvcRouterLinkActive="active" routerLink="/admin/users" appMobileSidebarToggler>
              <i class="nav-icon icon-user"></i> <ng-container i18n="@@sidebar_users-link">Users</ng-container>
            </a>
          </div>
          <div class="nav-item" *ngIf="userService.currentUserHasRole(UserRole.ACCOUNT_WRITE)">
            <a class="nav-link" data-test-id="sidebar_admin-account_preferences_link" uvcRouterLinkActive="active" routerLink="/admin/account-preferences" appMobileSidebarToggler>
              <i class="nav-icon fas fa-sliders-h"></i> <ng-container i18n="@@sidebar_account-preferences-link">Account Preferences</ng-container>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="userService.currentUserIsUVAdmin()" class="nav-item nav-dropdown mt-4" data-test-id="sidebar-uvaadmin" #uvaAdminNav #navDropdown>
        <a class="nav-link nav-dropdown-toggle" (click)="toggleNavDropdown(uvaAdminNav)">
          <i class="nav-icon fas fa-cogs"></i><ng-container i18n="@@sidebar_uvaadmin">UV Angel Admin</ng-container>
        </a>
        <div class="nav-dropdown-items">
          <div class="nav-item">
            <a class="nav-link" uvcRouterLinkActive="active" routerLink="/uvaadmin/dashboard" data-test-id="sidebar_link-uva-dashboard" appMobileSidebarToggler>
              <i class="nav-icon icon-speedometer"></i> <ng-container i18n="@@sidebar_uvaadmin-dashboard">Admin Dashboard</ng-container>
            </a>
          </div>
          <div class="nav-item" *ngIf="userService.currentUserHasRole(UserRole.USERS_READ, 'GLOBAL')">
            <a class="nav-link" data-test-id="sidebar_link-uva-users" uvcRouterLinkActive="active" routerLink="/uvaadmin/users" appMobileSidebarToggler>
              <i class="nav-icon icon-user"></i> <ng-container i18n="@@sidebar_uvaadmin-users-link">Users</ng-container>
            </a>
          </div>
          <div class="nav-item">
            <a class="nav-link" uvcRouterLinkActive="active" routerLink="/uvaadmin/devices" data-test-id="sidebar_link-uva-devices" appMobileSidebarToggler>
              <i class="nav-icon icon-layers"></i> <ng-container i18n="@@sidebar_uvaadmin-devices">Devices</ng-container>
            </a>
          </div>
        </div>
      </div>
    
    </div>
  </nav>
  <app-sidebar-minimizer></app-sidebar-minimizer>
</app-sidebar>