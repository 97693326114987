import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { asyncScheduler, fromEvent, of } from 'rxjs';
import { catchError, filter, first, map, startWith, switchMap, throttleTime } from 'rxjs/operators';
import { AccountEditModalComponent } from 'src/app/widget/modal/account-edit-modal/account-edit-modal.component';
import { SearchAccountModalComponent } from 'src/app/widget/modal/search-account-modal/search-account-modal.component';
import { UserPreferencesDialogComponent } from 'src/app/widget/user-preferences/components/user-preferences-dialog/user-preferences-dialog.component';
import { FullLocation, Location, ScopedUserAccount, User, UserAccount, UserRole } from '../../../../service/service.model';
import { AuthService } from '../../../../service/services/auth.service';
import { LocationService } from '../../../../service/services/location.service';
import { ThemeService } from '../../../../service/services/theme.service';
import { UserService } from '../../../../service/services/user.service';
import { ConfirmationModalComponent } from '../../../modal/confirmation-modal/confirmation-modal.component';

const SCREEN_LG_MIN_MEDIA_QUERY = '(min-width: 992px)';

@Component({
  selector: 'uvc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('growNShrink', [
      transition(':enter', [
        style({ height: '0' }),
        animate('.250s', style({ height: '*' })),
      ]),
      transition(':leave', [
        animate('.250s', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {

  @Output()
  subheaderShown = new EventEmitter<boolean>();

  public UserRole = UserRole;
  public user: User;
  public currentAccount: UserAccount;
  public currentLocation: FullLocation | Error;
  public currentThemeOption: string;
  public themeOptionLabels = {
    // keep this in sync with ThemeService
    'theme-light': 'Light Mode',
    'theme-dark': 'Dark Mode',
    system: 'System Default'
  };

  public notifications: Notification[] = [];
  public alerts: Alert[] = [];

  // signals if account & location bar is fully open in mobile view
  private _accountBarOpen = true;

  private prevScreenY: number|undefined;
  private locationPickerOpen = false;

  // true is using wide (desktop) screen, false if on mobile or small screen
  @HostBinding('@.disabled')
  public isOnDesktop = true;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private authService: AuthService,
    private locationService: LocationService,
    private modalService: BsModalService,
    private themeService: ThemeService,
    private router: Router) { }

  ngOnInit(): void {
    this.installResizeHandler();
    this.installScrollHandler();
    this.userService.currentUserAccount$().subscribe(account => {
      this.currentAccount = account;
    });
    this.userService.currentUser$().subscribe(user => this.user = user);
    this.locationService.currentLocation().pipe(
      catchError(error => of(error)),
    ).subscribe(location => this.currentLocation = location);
    this.themeService.currentTheme().pipe(map(({themeOption}) => themeOption)).subscribe(theme => this.currentThemeOption = theme);
  }

  installScrollHandler() {
    fromEvent(window, 'scroll').pipe(
      // work only on mobile view
      filter(() => !this.isOnDesktop),
      // react only to changes bigger than 5px
      filter(() => this.prevScreenY === undefined || Math.abs(this.prevScreenY - window.scrollY) >= 5),
      throttleTime(250, asyncScheduler, { leading: true, trailing: true }),
    ).subscribe(() => {
      /**
       * Open the account bar if scrolling up the screen and hide it when scrolling down.
       * Do NOT hide it when location picker is open otherwise it will close it.
       */
      if (this.prevScreenY === undefined) {
        this.accountBarOpen = window.scrollY < 5;
      } else {
        this.accountBarOpen = window.scrollY < this.prevScreenY || this.locationPickerOpen;
      }
      // clamp down the value to 0 as Safari on Mobile can go into negative values
      this.prevScreenY = window.scrollY < 0 ? 0 : window.scrollY;
    });
  }

  handlePickerOpen(open: boolean) {
    this.locationPickerOpen = open;
  }

  installResizeHandler() {
    fromEvent(window, 'resize').pipe(
      // to trigger at the time of installation
      startWith([]),
    ).subscribe(() => this.detectIsOnDesktop());
  }

  detectIsOnDesktop() {
    this.isOnDesktop = window.matchMedia(SCREEN_LG_MIN_MEDIA_QUERY).matches;
    this.accountBarOpen = !this.isOnDesktop;
  }

  changeCurrentAccount(account: ScopedUserAccount) {
    if (!account || this.currentAccount?.id === account.id) {
      return;
    }
    const lastLocation = this.currentLocation;
    this.currentLocation = null;
    // Change current account by navigating to dashboard
    this.router.navigate(['account', account.id, 'dashboard'], {
      queryParamsHandling: 'merge',
      preserveFragment: false,
    });
  }

  changeLocation(location: Location): void {
    const lastLocation = this.currentLocation;
    this.currentLocation = null;
    this.locationService.changeLocation(location).pipe(
      first()
    ).subscribe(
        () => { },
        e => {
          console.error(e);
          this.toastr.error('Could not change your location. Please try reloading the page.');
          this.currentLocation = lastLocation;
        }
    );
  }

  changeTheme(selectedTheme: string){
    this.themeService.changeTheme(selectedTheme);
  }

  openAccountSearch() {
    const modalRef = this.modalService.show(SearchAccountModalComponent, {
      initialState: { showLastUsedAccounts: true, doNotSelect: [this.currentAccount.id] }
    });
    modalRef.content.result.pipe(
      // read all account details
      switchMap(userAccount => this.userService.getAccount(userAccount.id)),
      // call again to fetch also account permissions of current user
      switchMap(userAccount => this.userService.getScopedAccount(userAccount.id)),
    ).subscribe(
      account => {
        this.changeCurrentAccount(account);
        modalRef.hide();
      },
      error => {
        // If an error occurs then show toaster and reopen dialog
        this.toastr.error('An error occured while switching to selected account', 'Error');
        modalRef.hide();
        this.openAccountSearch();
      });
  }

  createNewAccount() {
    const modalRef = this.modalService.show(AccountEditModalComponent, {
      initialState: {
        title: `Create Account`,
        account: null,
      }
    });
    modalRef.content.result.pipe(
      switchMap(data => this.userService.createAccount(data))
    ).subscribe(
      success => {
        modalRef.hide();
        this.toastr.success('New account has been created.');
      },
      err => this.toastr.error('There was an error while saving the account. Please try again.')
    );
  }

  openUserPreferences() {
    this.modalService.show(UserPreferencesDialogComponent);
  }

  logout() {
    const actions = [
      {label: 'Cancel', cssClass: ['btn', 'btn-secondary'], value: false},
      {label: 'Logout', cssClass: ['btn', 'btn-danger'], value: true}
    ];

    this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        title: 'Logout?',
        message: 'Are you sure you\'d like to logout?',
        actions
      }
    }).content.result.subscribe(confirmed => {
      if (confirmed) {
        this.authService.logout();
      }
    });
  }

  handleAccountBarToggle() {
    this.accountBarOpen = !this.accountBarOpen;
  }

  set accountBarOpen(open: boolean) {
    this._accountBarOpen = open;
    this.subheaderShown.emit(open);
  }

  get accountBarOpen() {
    return this._accountBarOpen;
  }

  get showAccountNav() {
    return !/^\/account\/.*\/admin\/(accounts)\/?/.test(this.router.url) &&
      // this is UVA Admin Dashboard page
      !/^\/uvaadmin\/dashboard\/?.*/.test(this.router.url) &&
      // this is UVA Admin Devices page
      !/^\/uvaadmin\/devices\/?.*/.test(this.router.url) &&
      // this is PublicDeviceInfo page
      !/^\/2d\/?.*/.test(this.router.url);
  }

  get showLocationNav() {
    return !/^\/account\/.*\/admin\/(accounts|location|users)\/?.*/.test(this.router.url) &&
      // this is Chart Detail
      !/^\/account\/.*\/chart\/?.*/.test(this.router.url) &&
      // this is PublicDeviceInfo page
      !/^\/2d\/?.*/.test(this.router.url) &&
      // this is UVA Admin Dashboard page
      !/^\/uvaadmin\/dashboard\/?.*/.test(this.router.url) &&
      // this is UVA Admin Devices page
      !/^\/uvaadmin\/devices\/?.*/.test(this.router.url) &&
      // this is Device Search page
      !/^\/account\/.*\/devices\/?.*/.test(this.router.url);
  }

}

interface Message {
  text: string;
  severity: 'danger' | 'warning';
}

type Notification = Message;
type Alert = Message;
