import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RoutingModule} from './routing/routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ChartsModule} from 'ng2-charts';
import {AppComponent} from './app.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ServiceModule} from './service/service.module';
import {NavigationModule} from './widget/navigation/navigation.module';
import {UserModule} from './user/user.module';
import {AvatarModule} from 'ngx-avatar';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProvidersModule } from './widget/providers/providers.module';
import { ModalModule, ModalOptions, MODAL_CONFIG_DEFAULT_OVERRIDE } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { LAST_USED_ACCOUNTS_LENGTH } from './service/services/storage.service';

const avatarColors = [
  '#0093c7', // blue
  '#f8cb00', // orange
  '#f86c6b', // red
  '#ffc107', // yellow
  '#4dbd74', // green
  '#20c997', // teal
  '#17a2b8', // cyanh
  '#6610f2', // indigo
  '#6f42c1', // purple
  '#e83e8c', // pink
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AvatarModule.forRoot({colors: avatarColors}),
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
    RoutingModule,
    ServiceModule.forRoot(),
    NavigationModule,
    TimepickerModule.forRoot(),
    UserModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    ProvidersModule,
    ButtonsModule.forRoot(),
  ],
  providers: [
    {
      provide: LAST_USED_ACCOUNTS_LENGTH,
      useValue: 5,
    },
    // Configure globally all modals to have static backdrop
    {
      provide: MODAL_CONFIG_DEFAULT_OVERRIDE,
      useValue: {
        backdrop: 'static',
      } as ModalOptions
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
