import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { mapValues } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserAccount, UserAccountData } from 'src/app/service/service.model';
import { CustomValidators } from '../../validators/custom.validators';
import { BaseModalComponent } from '../base-modal/base-modal.component';

const LOCATION_TYPES = {
  campus: ['CAMPUS',  'BUILDING',  'FLOOR',  'ROOM/AREA',  'OBJECT',  'SURFACE'],
  office: ['OFFICE',  'BUILDING',  'FLOOR',  'ROOM/AREA',  'OBJECT',  'SURFACE']
};

@Component({
  selector: 'uvc-accoount-edit-modal',
  templateUrl: './account-edit-modal.component.html',
  styleUrls: ['./account-edit-modal.component.scss']
})
export class AccountEditModalComponent extends BaseModalComponent<UserAccountData> implements OnInit {

  @Input() account: UserAccount;

  public saving = false;
  public accountForm: FormGroup;

  constructor(protected modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit() {
    // TODO set id and location types
    this.accountForm = new FormGroup({
      name: new FormControl(this.account?.name || '', [Validators.required, CustomValidators.notEmpty]),
      locationTypes: new FormControl('office', [Validators.required])
    });
  }

  get accountName() { return this.accountForm.get('name'); }

  doCancel() {
    this.dismiss();
  }

  doSave() {
    if (this.accountForm.valid) {
      this.saving = true;
      const acc: UserAccountData = mapValues(this.accountForm.value, (value, key) => {
        return (key === 'locationTypes') ? LOCATION_TYPES[value] : value.trim();
      });
      this.resultSubject.next(acc);
    }
  }
}
