import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { FullLocation } from '../service/service.model';
import { LocationService } from '../service/services/location.service';

/**
 * This guard makes sure that there is 'locationId' query parameter in URL.
 * If it is not there then it will take current location and put it in URL query params.
 * If location id is in the URL but account+location combination is invalid then
 * user will be redirected to account root location and an error message will be shown.
 *
 * When you use this resolver, make sure to set
 * runGuardsAndResolvers: 'paramsOrQueryParamsChange',
 */
@Injectable({ providedIn: 'root' })
export class ValidLocationInUrlGuard implements CanActivate {

  private currentLocation: FullLocation;

  constructor(
    private toast: ToastrService,
    private locationService: LocationService,
    private router: Router,
  ) {
    this.locationService.currentLocation().subscribe(location => this.currentLocation = location);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const putCurrentLocationInUrl = this.locationService.currentLocation().pipe(
      take(1),
      map(location => this.router.createUrlTree([state.url], {
        queryParams: {
          locationId: location.id,
        },
        // queryParamsHandling: 'merge',
      })),
    );

    let locationId = null;
    if (typeof route.queryParams.locationId === 'string') {
      locationId = route.queryParams.locationId;
    } else if (Array.isArray(route.queryParams.locationId) && route.queryParams.locationId.length > 0) {
      locationId = route.queryParams.locationId[0];
    }

    if (!locationId) {
      return putCurrentLocationInUrl;
    }

    // If there is a location id in URL then compare it to current location id.
    // If they are equal then proceed.
    // If they are different then try to change location. If that faild then fall
    // back to current location.
    return this.locationService.currentLocation().pipe(
      take(1),
      switchMap(currLocation => {
        if (currLocation.id === locationId) {
          return of(true);
        }

        return this.locationService.changeLocation({id: locationId, name: null}).pipe(
          map(() => true),
          catchError(error => {
            console.error('Invalid account/location id in URL', error);
            this.toast.error(`The specified location is invalid.\You are now viewing data from your most previous location for this account.`, 'Invalid Location');
            return putCurrentLocationInUrl;
          })
        );
      }),
    );
  }
}
