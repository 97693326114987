import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryOptions } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FullLocation, Location, LocationData, LocationSearchResult, UserAccount } from '../service.model';
import { StorageService } from './storage.service';
import { NO_ACCOUNT, UserService } from './user.service';

const getLocationById = gql`
  query locationById ($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId){
      id
      type
      name
      description
      tags
      createdAt
      createdBy {
        id
        username
        email
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        username
        email
        firstName
        lastName
      }
      fullLocationPath {
        id
        name
        type
        description
        tags
        immediateSublocations {
          id
          name
          type
          description
          tags
        }
      }
      immediateSublocations {
        id
        name
        type
        description
        tags
        createdAt
        updatedAt
      }
    }
}`;

const searchLocations = gql`
  query locationBySearchString ($accountId: ID!, $searchString: String!) {
    locations(accountId: $accountId, searchString: $searchString) {
      id
      name
      tags
      description
      fullLocationPath {
        id
        name
      }
    }
  }
`;

const removeLocation = gql`
mutation removeLocation($accountId: ID!, $locationId: ID!) {
  removeLocation(accountId: $accountId, locationId: $locationId) {
    success,
    errorMessage
  }
}
`;

const updateLocation = gql`
mutation updateLocation($accountId: ID!, $locationId: ID!, $type: String!,
                        $name: String!, $description: String, $tags: [String]) {
  updateLocation(
    accountId: $accountId,
    locationId: $locationId,
    type: $type,
    name: $name,
    description: $description,
    tags: $tags) {
    id
    type
    name
    description
    tags
    updatedAt
    updatedBy {
      id
      username
      email
      firstName
      lastName
    }
    createdAt
    createdBy {
      id
      username
      email
      firstName
      lastName
    }
    fullLocationPath {
      id
      name
      type
      description
      tags
      immediateSublocations {
        id
        name
        type
        description
        tags
      }
    }
    immediateSublocations {
      id
      name
      type
      description
      tags
    }
  }
}
`;

const createSubLocation = gql`
mutation createSubLocation($accountId: ID!, $parentLocationId: ID!,
                          $type: String!, $name: String!, $description: String, $tags: [String]) {
  createLocation(accountId: $accountId,
                 parentLocationId: $parentLocationId,
                 type: $type,
                 name: $name,
                 description: $description,
                 tags: $tags){
    id
    type
    name
    description
    updatedAt
    updatedBy {
      id
      username
      email
      firstName
      lastName
    }
    createdAt
    createdBy {
      id
      username
      email
      firstName
      lastName
    }
  }
 }`;

type RemoveLocationResponse = {
  success: boolean,
  errorMessage: string,
};

@Injectable({
  providedIn: 'root'
})
export class LocationService implements OnDestroy {
  // Emit Location object in ReplaySubject with size 1 instead of BehaviorSubject, because BehaviorSubject requires an initial
  // value, which we do not have. We want the rest of the app to just wait for the first Location object.
  private locationSubject = new ReplaySubject<FullLocation>(1);
  private currentAccount: UserAccount;

  constructor(private apollo: Apollo,
              private userService: UserService,
              private storageService: StorageService,
              private currentRoute: ActivatedRoute,
              private router: Router,
  ) {
    this.userService.currentUserAccount$()
        .pipe(
          filter(account => account !== NO_ACCOUNT),
          tap(account => this.currentAccount = account),
          switchMap(account => this.getFullLocationFromStorageOrAccount(account))
        ).subscribe(this.locationSubject);
  }

  public changeLocation(location: Location): Observable<FullLocation> {
    let obs: Observable<FullLocation>;
    if (location instanceof FullLocation) {
      obs = of(location);
    } else {
      obs = this.getFullLocationById(location.id, null, true);
    }
    obs = obs.pipe(
      tap(loc => this.storageService.setSelectedLocationId(loc.id, this.currentAccount?.id)),
      tap(loc => this.locationSubject.next(loc))
    );
    return obs;
  }

  public currentLocation(): Observable<FullLocation> {
    return this.locationSubject.asObservable();
  }

  /**
   * Get locationId from URL query params.
   * In case query params don't have locationId and fallbackToCurrentLocation is true
   * then current location's id is put in URL query params.
   *
   * @returns location id
   */
  public locationIdFromUrl<T extends string|string[] = string>(fallbackToCurrentLocation = false): Observable<T> {
    return this.currentRoute.queryParams.pipe(
      map(params => params.locationId),
      switchMap(locationId => {
        if (locationId && (typeof locationId === 'string' || (Array.isArray(locationId) && locationId.length > 0))) {
          return of(typeof locationId === 'string' ? locationId : locationId[0]);
        }
        if (fallbackToCurrentLocation) {
          return this.currentLocation().pipe(
            take(1),
            tap(location => this.reloadWithLocation(location.id)),
            map(() => null),
          );
        }
        return of(null);
      }),
      filter(locationId => !!locationId),
      map(locationId => locationId as T)
    );
  }

  /**
   * Get location based on location id from URL query params.
   * In case query params don't have locationId and fallbackToCurrentLocation is true
   * then current location is put in URL query params and returned.
   *
   * @returns location id
   */
   public locationFromUrl<T extends Location|Location[] = Location>(fallbackToCurrentLocation = false): Observable<T> {
    return this.currentRoute.queryParams.pipe(
      map(params => params.locationId),
      switchMap(locationId => {
        if (locationId && (typeof locationId === 'string' || (Array.isArray(locationId) && locationId.length > 0))) {
          return this.getFullLocationById(typeof locationId === 'string' ? locationId : locationId[0], this.currentAccount.id, false);
        }
        if (fallbackToCurrentLocation) {
          return this.currentLocation().pipe(
            take(1),
            tap(location => this.reloadWithLocation(location.id)),
            map(() => null),
          );
        }
        return of(null);
      }),
      filter(locationId => !!locationId),
      map(locationId => locationId as T)
    );
  }

  /**
   * Sync current location into URL.
   * Stop when stop observable emits value
   */
  public syncLocationToUrlUntil(stop: Observable<any>, skipFirst = true, callback: (location: FullLocation) => void = null) {
    this.currentLocation().pipe(
      // currentLocation always emits 1x current location on subscription but we want to listen only on future changes
      skip(skipFirst ? 1 : 0),
      takeUntil(stop),
    ).subscribe(location => {
      if (callback) {
        callback(location);
      }
      this.reloadWithLocation(location.id);
    });
  }

  public reloadWithLocation(locationId: string) {
    this.router.navigate([], {
      queryParams: { locationId },
      queryParamsHandling: 'merge',
    });
  }

  public ngOnDestroy() {
    this.locationSubject.complete();
  }

  public getFullLocationById(locationId: string, accountId: string, ignoreCache: boolean): Observable<FullLocation> {
    const queryParams: QueryOptions = {
      query: getLocationById,
      context: {operationName: `locationById&id=${locationId}`},
      variables: {
        accountId: accountId || this.currentAccount.id,
        locationId
      },
      fetchPolicy: (ignoreCache ? 'no-cache' : 'cache-first')
    };
    return this.apollo.query<{location: LocationData}>(queryParams).pipe(
      map(({data}) => {
        if (!data?.location) {
          throw Error('Could not find location for id ' + locationId);
        }
        return new FullLocation(data.location);
      })
    );
  }

  public searchLocations(searchString: string, ignoreCache: boolean): Observable<any> {
    const queryParams: QueryOptions = {
      query: searchLocations,
      variables: {
        accountId: this.currentAccount.id,
        searchString
      },
      fetchPolicy: (ignoreCache ? 'no-cache' : 'cache-first')
    };
    return this.apollo.query<{locations: LocationData[]}>(queryParams).pipe(
        map(data => data.data.locations.map(l => new LocationSearchResult(l)))
    );
  }

  public deleteSublocation(accountId: string, locationId: string) {
    return this.apollo.mutate<{removeLocation: RemoveLocationResponse}>({
      mutation: removeLocation,
      variables: { accountId, locationId }
    }).pipe(
      map(({data}) => {
        if (!data.removeLocation.success) {
          throw data.removeLocation.errorMessage;
        }
        return data.removeLocation.success;
      }),
    );
  }

  public updateSubLocation(accountId: string, locationId: string, type: string, name: string, description: string, tags: string[]) {
    return this.apollo.mutate<{updateLocation: LocationData}>({mutation: updateLocation, variables: {
      accountId,
      locationId,
      type,
      name,
      description,
      tags
    }}).pipe(
      map(({data}) => {
        return new FullLocation(data.updateLocation);
      })
    );
  }

  public createSubLocation(accountId: string, parentLocationId: string, type: string, name: string, description?: string, tags?: string[]) {
    return this.apollo.mutate({mutation: createSubLocation, variables: {
      accountId,
      parentLocationId,
      type,
      name,
      description,
      tags
    }}).pipe(
      map(({data}: any) => {
        return new FullLocation(data.createLocation);
      })
    );
  }

  private getFullLocationFromStorageOrAccount(account: UserAccount): Observable<FullLocation> {
    const storageId = this.storageService.getSelectedLocationId(account.id);
    const locationFromStorage: Observable<FullLocation | null> = storageId
        ? this.getFullLocationById(storageId, account?.id, true).pipe(catchError(e => {
          console.error(e);
          return of(null);
        }))
        : of(null);
    return locationFromStorage.pipe(
        switchMap(l => (l ? of(l) : this.getFullLocationById(account.rootLocation.id, account.id, true))),
        tap(location => this.storageService.setSelectedLocationId(location.id, account.id))
    );
  }
}
