import {isString, isArray, isObject, union, isNumber} from 'lodash-es';


// User related stuff

export enum UserRole {
    // GlobalRoles
    ACCOUNT_CREATE,
    ACCOUNT_REMOVE,
    // AccountRoles
    ACCOUNT_READ,
    ACCOUNT_WRITE,
    REPORT_READ,
    REPORT_WRITE,
    DEVICE_READ,
    DEVICE_WRITE,
    USERS_READ,
    USERS_WRITE,
    LOCATION_READ,
    LOCATION_WRITE
}

export const ROLE_KEYS = Object.keys(UserRole).filter(role => isNaN(Number(role)));

// tslint:disable: variable-name
export interface UserRoleData {
    // global roles
    account_create?: boolean;
    account_remove?: boolean;
    // account roles
    account_read?: boolean;
    account_write?: boolean;
    report_read?: boolean;
    report_write?: boolean;
    device_read?: boolean;
    device_write?: boolean;
    users_read?: boolean;
    users_write?: boolean;
    location_read?: boolean;
    location_write?: boolean;
}

export function toUserRoleList(data: UserRoleData): UserRole[] {
    const list = [];
    // tslint:disable: no-unused-expression
    if (data) {
        data.account_create && list.push(UserRole.ACCOUNT_CREATE);
        data.account_remove && list.push(UserRole.ACCOUNT_REMOVE);
        data.account_read && list.push(UserRole.ACCOUNT_READ);
        data.account_write && list.push(UserRole.ACCOUNT_WRITE);
        data.report_read && list.push(UserRole.REPORT_READ);
        data.report_write && list.push(UserRole.REPORT_WRITE);
        data.device_read && list.push(UserRole.DEVICE_READ);
        data.device_write && list.push(UserRole.DEVICE_WRITE);
        data.users_read && list.push(UserRole.USERS_READ);
        data.users_write && list.push(UserRole.USERS_WRITE);
        data.location_read && list.push(UserRole.LOCATION_READ);
        data.location_write && list.push(UserRole.LOCATION_WRITE);
    }
    // tslint:enable: no-unused-expression
    return list;
}

export interface UserData {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    globalRoles?: UserRoleData;
    status?: string;
    lastLogin?: Date;
    createdAt?: Date;
    updatedAt?: Date;
    agreements?: {
        type: string;
        version: string;
        agreedAt: string;
    }[];
    preferences?: {
        defaultAccountId?: string;
    };
    memberAccounts?: ScopedUserAccountData[];
}

export interface SpecificAccountScopeData {
    account?: UserAccountData;
    accountScope?: UserAccountScopeData;
}
export interface UserAccountScopeData {
    roles?: UserRoleData;
    rootLocation?: LocationData;
}

export interface UserAgreement {
    type: string;
    version: string;
    agreedAt: Date;
}

export interface UserPreferences {
    defaultAccountId?: string;
}
export class User {
    readonly id: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
    readonly username?: string;
    readonly status?: string;
    readonly agreements?: UserAgreement[];
    readonly preferences?: UserPreferences;
    readonly lastLogin: Date;
    readonly createdAt: Date;
    readonly updatedAt?: Date;

    public accounts: ScopedUserAccount[];
    public globalRoles: UserRole[];

    constructor(data: UserData, accountScopes?: SpecificAccountScopeData[]) {
        if (!isString(data.id)) {
            throw new Error(`Could not create user from data (${data}): missing id value`);
        }
        if (data.memberAccounts?.length && accountScopes?.length) {
            throw new Error('User can not be initialized with both memberAccounts and accountScopes!');
        }

        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.username = data.username;
        this.status = data.status;

        if (data.lastLogin){
            try { this.lastLogin = new Date(data.lastLogin); } catch (e) { console.error(e); }
        }
        if (data.createdAt){
            try { this.createdAt = new Date(data.createdAt); } catch (e) { console.error(e); }
        }
        if (data.updatedAt){
            try { this.updatedAt = new Date(data.updatedAt); } catch (e) { console.error(e); }
        }

        this.globalRoles = toUserRoleList(data.globalRoles);

        this.agreements = [];

        if (data.agreements) {
            data.agreements.filter(agg => !!agg).map(agg => {
                const agreement: UserAgreement = {
                    type: agg.type,
                    version: agg.version,
                    agreedAt: null,
                };
                try { agreement.agreedAt = new Date(agg.agreedAt); } catch (e) { console.error(e); }
                this.agreements.push(agreement);
            });
        }

        this.preferences = {...data.preferences};

        if (isArray(data.memberAccounts)) {
            this.accounts = data.memberAccounts.map(
                madata => new ScopedUserAccount(madata, toUserRoleList(madata.userAccountScope?.roles))
            );
        } else if (isArray(accountScopes)) {
            this.accounts = accountScopes
            .map(({account, accountScope: {roles, rootLocation}}) => {
                try {
                    // If user doesn't have root loaction set (in his scope) then use account's root location
                    const userRootLocation = rootLocation ? rootLocation : account.rootLocation;
                    return new ScopedUserAccount({...account, rootLocation: userRootLocation}, toUserRoleList(roles));
                } catch (e) {
                    console.log(e);
                    return null;
                }
            })
            .filter(userAccount => userAccount !== null);
        }
    }

    get fullName() {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return null;
    }

    /**
     * Check if user has all given roles.
     * If accountId is provided and it is this user's account then that account's roles are checked.
     * Otherwise only global roles are checked.
     */
    hasRole(roles: UserRole | UserRole[], accountId?: string) {
        if (roles === undefined || roles === null) {
            return true;
        }
        const account = this.accounts.find(acct => acct.id === accountId);
        const userRoles = union(this.globalRoles, account ? account.roles : []);
        if (Array.isArray(roles)) {
            return roles.reduce((result, role) => result && userRoles.includes(role), true);
        } else {
            return userRoles.includes(roles);
        }
    }

    /**
     * Check if user has any given role.
     * If accountId is provided and it is this user's account then that account's roles are checked.
     * Otherwise only global roles are checked.
     */
    hasAnyRole(roles: UserRole[], accountId?: string) {
        if (roles === undefined || roles === null) {
            return true;
        }
        const account = this.accounts.find(acct => acct.id === accountId);
        const userRoles = union(this.globalRoles, account ? account.roles : []);
        return roles.reduce((result, role) => result || userRoles.includes(role), false);
    }
}

export enum UnitOfMeasures {
    imperial = 'imperial',
    metric = 'metric'
}

export interface AccountPreferences {
    logo_image_url: string;
    unit_of_measure: UnitOfMeasures;
    preferred_timezone: string;
}

export interface AccountPreferencesInput {
    logo_image_id: string;
    unit_of_measure: UnitOfMeasures;
    preferred_timezone: string;
}

/**
 * Account structure used on backend
 */
export interface UserAccountData {
    id?: string;
    name?: string;
    rootLocation?: LocationData;
    locationTypes?: string[];
    datasources?: UserAccountDatasource[];
    createdAt?: Date;
    createdBy?: User;
    updatedBy?: User;
    updatedAt?: Date;
    numLocations?: number;
    numMembers?: number;
    deviceTypes?: string[];
    preferences?: AccountPreferences;
}

export interface ScopedUserAccountData extends UserAccountData{
    userAccountScope?: UserAccountScopeData;
}

export type DeviceTypeId = 'UVA10' | 'UVA20' | 'AIR175' | 'AIR20';

export class UserAccount {
    readonly id: string;
    name?: string;
    datasources?: UserAccountDatasource[];
    locationTypes?: string[];
    updatedAt?: Date;
    updatedBy?: User;
    createdAt?: Date;
    createdBy?: User;
    numLocations?: number;
    numMembers?: number;
    deviceTypes: DeviceTypeId[];
    preferences?: AccountPreferences;

    private _rootLocation: Location;

    constructor(data: Partial<UserAccountData> | UserAccount) {
        if (!isString(data.id)) {
            throw new Error(`Could not create user account from data (${data}): missing id value`);
        }
        this.id = data.id;
        this.name = data.name;
        this.datasources = data.datasources ? [...data.datasources] : [];
        this.locationTypes = data.locationTypes ? [...data.locationTypes] : [];
        if (data.createdAt) {
            try {
                this.createdAt = new Date(data.createdAt);
            } catch (err) {
                console.error(err);
            }
        }
        if (data.updatedAt) {
            try {
                this.updatedAt = new Date(data.updatedAt);
            } catch (err) {
                console.error(err);
            }
        }
        this.createdBy = data.createdBy;
        this.updatedBy = data.updatedBy;
        this.setRootLocation(data.rootLocation);
        this.numLocations = data.numLocations;
        this.numMembers = data.numMembers;
        this.deviceTypes = data.deviceTypes ? [...data.deviceTypes] as DeviceTypeId[] : [];
        if (data.preferences) {
            this.preferences = {...data.preferences};
        }
    }

    setRootLocation(location: LocationData | Location) {
        if (isObject(location)) {
            try { this._rootLocation = new Location(location); } catch (e) { console.error(e); }
        }
    }

    get rootLocation() {
        return this._rootLocation;
    }

    hasDatasource(types: AccountDatasourceType | AccountDatasourceType[]): boolean {
        if (Array.isArray(types)) {
            return types.some(type => this.hasDatasource(type));
        } else {
            return this.datasources?.some(ds => ds.datasourceType  === types);
        }
    }

}

/**
 * Scoped user account has data of certain user tied to given account.
 */
export class ScopedUserAccount extends UserAccount {
    private _roles: UserRole[];

    constructor(data: UserAccountData | UserAccount, accountRoles: UserRole[]) {
        super(data);
        this.setRoles(accountRoles);
    }

    setRoles(accountRoles: UserRole[]) {
        if (!accountRoles) {
            throw new Error('accounRoles must be specified');
        }
        this._roles = [...accountRoles];
    }

    get roles() {
        return this._roles;
    }

    hasRole(roles: UserRole | UserRole[]) {
        if (Array.isArray(roles)) {
            return roles.reduce((result, role) => result && this.roles?.includes(role), true);
        } else {
            return this.roles?.includes(roles);
        }
    }

    hasAnyRole(roles: UserRole[]) {
        return roles.reduce((result, role) => result || this.roles?.includes(role), false);
    }
}

export type AccountDatasourceType = 'SURFACIDE' | 'CENTRAK' | 'OPTISOLVE' | 'ZAN';

export interface UserAccountDatasource {
    id: string;
    datasourceType: AccountDatasourceType;
    datasourceName: string;
    dataSourceDescription?: string;
}

export interface UserSearchParams {
    status?: [UserStatusFilter];
    searchQuery?: string;
}

export enum UserStatusFilter {
    CONFIRMED,
    FORCE_CHANGE_PASSWORD,
}


// Location related stuff
export interface LocationData {
    id?: string;
    name?: string;
    description?: string;
    type?: string;
    tags?: string[];

    immediateSublocations?: LocationData[];

    createdAt?: Date;
    createdBy?: UserData;
    updatedAt?: Date;
    updatedBy?: UserData;
    fullLocationPath?: LocationData[];
}

export class Location {
    readonly id: string;
    readonly name: string;
    readonly description?: string;
    readonly type?: string;
    readonly tags?: string[];
    readonly createdAt?: Date;
    readonly updatedAt?: Date;

    constructor(data: LocationData | Location) {
        if (!isString(data.id)) {
            throw new Error(`Could not create LocationCore from data (${data}): missing id value`);
        }
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.type = data.type;
        if (isArray(data.tags)) {
            this.tags = data.tags.filter(t => isString(t));
        }
        if (data.createdAt) {
            try {
                this.createdAt = new Date(data.createdAt);
            } catch (err) {
                console.error(err);
            }
        }
        if (data.updatedAt) {
            try {
                this.updatedAt = new Date(data.updatedAt);
            } catch (err) {
                console.error(err);
            }
        }
    }
}

export class LocationWithSublocations extends Location {
    readonly immediateSublocations: Location[] = [];

    constructor(data: LocationData | LocationWithSublocations) {
        super(data);

        if (isArray(data.immediateSublocations)) {
            this.immediateSublocations = (data.immediateSublocations as Array<LocationData|Location>)
                .map(l => { try { return new Location(l); } catch (e) { console.error(e); return null; }})
                .filter(l => l !== null);
        }
    }
}

export class FullLocation extends LocationWithSublocations {
    readonly fullLocationPath: LocationWithSublocations[];
    readonly updatedBy?: User;
    readonly createdBy?: User;

    // TODO: Deprecate / remove this once no longer needed
    floorMapUrl?: string;

    constructor(data: LocationData | FullLocation) {
        super(data);

        if (data.updatedBy) {
            try {
                this.updatedBy = data.updatedBy instanceof User ? data.updatedBy : new User(data.updatedBy);
            } catch (e) { console.error(e); }
        }
        if (data.createdBy) {
            try {
                this.createdBy = data.createdBy instanceof User ? data.createdBy : new User(data.createdBy);
            } catch (e) { console.error(e); }
        }

        if (isArray(data.fullLocationPath)) {
            this.fullLocationPath = (data.fullLocationPath as Array<LocationData|LocationWithSublocations>)
                .map(l => { try { return new LocationWithSublocations(l); } catch (e) { console.error(e); return null; }})
                .filter(l => l !== null);
        }

        // TODO remove when backend works
        this.floorMapUrl = '/assets/floor-plans/floor-plan.svg';
    }

    /**
     * Get full location as a string separated by '/'
     */
    public get locationPath() {
        const path = this.fullLocationPath || [];
        return [...path.map(p => p.name), this.name].join('/');
    }
}

export class LocationSearchResult {
    readonly location: Location;
    readonly locationPath: Location[] = [];
    constructor(data: LocationData) {
        this.location = new Location(data);
        if (isArray(data.fullLocationPath)) {
            this.locationPath = data.fullLocationPath
                .filter(l => l.name?.toLowerCase() !== 'all locations')
                .map(l => new Location(l));
        }
    }
}

export interface PaginatedResponseData<D> {
    nextToken?: string;
    items: D[];
    totalCount?: number;
}

export interface PaginatedInput {
    maxResults?: number;
    nextToken?: string;
}

export class PaginatedResponse<T, D> {
    readonly nextToken?: string;
    readonly items: T[];
    readonly totalCount?: number;

    // https://stackoverflow.com/questions/17382143/create-a-new-object-from-type-parameter-in-generic-class
    constructor(data: PaginatedResponseData<D>, tConstructor: new(...args: any[]) => T ) {
        if (isString(data.nextToken)) {
            this.nextToken = data.nextToken;
        }
        this.items = (isArray(data.items) ? data.items : [])
            .map(itemData => { try { return new tConstructor(itemData); } catch (e) { console.error(e); return null; }})
            .filter(item => item !== null);

        if (isNumber(data.totalCount)) {
            this.totalCount = data.totalCount;
        } else if (isArray(this.items)) {
            this.totalCount = this.items.length;
        } else {
            this.totalCount = 0;
        }
    }

    public hasMoreItems(): boolean {
        return isString(this.nextToken);
    }

    public concat(data: PaginatedResponseData<D>, tConstructor: new(...args: any[]) => T ): PaginatedResponse<T, D> {
        const newItemList = new PaginatedResponse<T, D>(data, tConstructor);
        newItemList.items.unshift(...this.items);
        return newItemList;
    }
}
