// This file includes polyfills needed by Angular and is loaded before
// the app. You can add your own extra polyfills to this file.
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/reflect';

import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

import '@angular/localize/init';


// Declare global interface for Object, so we can extend it and Typescript recognizes it throughout the project
declare global {
    interface Object {
        fromValue: <T>(value: string) => T | undefined;
    }
}

/**
 * Utility function for reverse mapping a string enum value.
 *
 * String enums have no reverse mapping, see https://www.typescriptlang.org/docs/handbook/enums.html#enums-at-runtime.
 * Therefore, this function iterates through the enum values to see if a matching value exist,
 * and returns that value if it exists, and otherwise returns undefined.
 */
Object.defineProperty(Object.prototype, 'fromValue', {
    value: fromValue,
    writable: false
});

function fromValue<T>(value: string): T | undefined {
    let key: string;
    for (key in this) {
        if (this.hasOwnProperty(key)) {
            const enumValue = this[key];
            if (value === enumValue) {
                return enumValue;
            }
        }
    }
    return undefined;
}

(window as any).global = window;
