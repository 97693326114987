import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {

    private destroySubject = new Subject<boolean>();
    protected destroyed$ = this.destroySubject.asObservable();

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
        this.destroySubject.unsubscribe();
    }

}
