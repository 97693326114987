import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SearchAccountModalComponent } from 'src/app/widget/modal/search-account-modal/search-account-modal.component';
import { UserAccount } from '../../../../service/service.model';

export const ALL_ACCOUNTS = new UserAccount({ id: '-1', name: 'All Accounts'});

@Component({
  selector: 'uvc-account-picker',
  templateUrl: './account-picker.component.html',
  styleUrls: ['./account-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AccountPickerComponent),
    multi: true
  }]
})
export class AccountPickerComponent implements ControlValueAccessor {

  disabled = false;
  public selectedAccount: UserAccount;
  @Input() includeAllAccounts = false;
  @Input() staticData: UserAccount[];

  constructor(private modalService: BsModalService){ }

  writeValue(value: UserAccount): void {
      this.selectedAccount = value;
      if (value === ALL_ACCOUNTS) {

      }
  }

  public openAccountSearchModal() {
    const modalRef = this.modalService.show(SearchAccountModalComponent, {
      initialState: {
        staticData: this.staticData
      }
    });
    modalRef.content.result.subscribe(account => {
      this.onChange(account);
      modalRef.hide();
    });
  }

  onChange = (_) => { };
  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
