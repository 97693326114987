import { Attribute, Component, Input } from '@angular/core';

@Component({
  selector: 'uvc-logo',
  templateUrl: './logo.component.html'
})
export class LogoComponent {

  @Input()
  w: string;

  @Input()
  h: string;

  // This sets width/height when used in index.html
  constructor(@Attribute('w') width: string, @Attribute('h') height: string) {
    this.w = width;
    this.h = height;
  }

}
