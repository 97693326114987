import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.env === 'production') {
  enableProdMode();
  if (window?.console) {
    window.console.debug = () => {};
  }
}

// dont initialize angular on '/logout' url
// set class on page body instead, so logout message is shown
if (window.location.pathname !== '/logout'){
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
}
