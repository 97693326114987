import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { User, UserAccount } from 'src/app/service/service.model';
import { UserService } from 'src/app/service/services/user.service';
import { BaseModalComponent } from 'src/app/widget/modal/base-modal/base-modal.component';
import { AccountPickerComponent } from '../account-picker/account-picker.component';

@Component({
  selector: 'uvc-user-preferences-dialog',
  templateUrl: './user-preferences-dialog.component.html',
  styleUrls: ['./user-preferences-dialog.component.scss']
})
export class UserPreferencesDialogComponent extends BaseModalComponent<never> implements OnInit {

  @ViewChild(AccountPickerComponent)
  accountPicker: AccountPickerComponent;

  title = 'User Preferences';
  currentDefaultAccountId = '';
  defaultAccountCtrl = new FormControl();

  user: User;
  loadingAccount = false;

  constructor(
    modalRef: BsModalRef,
    private userService: UserService,
    private toast: ToastrService,
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.userService.currentUser$().pipe(
      tap(user => this.user = user),
      filter(user => !!user.preferences.defaultAccountId),
      tap(() => this.loadingAccount = true),
      switchMap(user => {
        const memberAccount = user.accounts.find(acc => acc.id === user.preferences.defaultAccountId);
        if (memberAccount) {
          return of(memberAccount);
        } else {
          return this.userService.getAccount(user.preferences.defaultAccountId);
        }
      }),
      takeUntil(this.destroyed$),
    ).subscribe(account => {
      this.loadingAccount = false;
      this.defaultAccountCtrl.setValue(account, { emitEvent: false });
    });

    this.defaultAccountCtrl.valueChanges.pipe(
      switchMap((account: UserAccount) => this.userService.updateUserPreferences({
        defaultAccountId: account?.id,
      }).pipe(
        catchError(error => {
          this.toast.error(error.message, 'Error saving user preferences');
          return of(null);
        }),
      )),
      filter(updatedUser => !!updatedUser),
      takeUntil(this.destroyed$),
    ).subscribe(
      (updatedUser: User) => {
        this.toast.success('User preferences updated', 'Success');
        this.user.preferences.defaultAccountId = updatedUser.preferences.defaultAccountId;
        this.modalRef.hide();
      }
    );
  }
}
