import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {finalize, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {debounce, isString, last} from 'lodash-es';
import {NgSelectComponent} from '@ng-select/ng-select';
import {FullLocation, Location, LocationSearchResult, LocationWithSublocations, UserAccount} from '../../../../service/service.model';
import {LocationService} from '../../../../service/services/location.service';
import {CancellableEvent} from '../../../../common/models/cancellable-event';
import { of } from 'rxjs';

@Component({
  selector: 'uvc-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit {

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  public loadingFullLocation = true;
  public loadingSearchResult = false;
  public locationPath: LocationSelectorData[] = [];
  public selectDropdownItems: LocationSearchResult[] | null = null;
  public searchTerm = '';

  public searchDebounced = debounce(data => this.search(data.term), 500);

  @HostBinding('class.d-none') private _isHidden = true;
  @Output() isHiddenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() account: UserAccount;
  @Input() public set isHidden(value) {
    this._isHidden = value;
  }
  @Output() locationChange = new EventEmitter<FullLocation | CancellableEvent<FullLocation>>();
  @Input() autoHide = true;
  @Input() cancellable = false;
  @Input() preselectLocation: FullLocation = null;

  constructor(@Inject(DOCUMENT) private document: Document,
              private toastrService: ToastrService,
              private locationService: LocationService,
              private eRef: ElementRef) { }

  ngOnInit(): void {
    (this.preselectLocation instanceof FullLocation ? of(this.preselectLocation) : this.locationService.currentLocation()).pipe(
      take(1),
    ).subscribe(location => {
      this.loadingFullLocation = false;
      this.setLocationPath(location);
    });
  }

  public selectSublocation(locationSelectorData: LocationSelectorData): void {
    this.ngSelectComponent.handleClearClick();
    this.ngSelectComponent.blur();
    this.removeLocationSelectorDataAfter(locationSelectorData);
    if (!locationSelectorData.selectedSublocation) {
      return;
    }

    this.loadingFullLocation = true;
    this.locationService.getFullLocationById(locationSelectorData.selectedSublocation.id, this.account?.id, true)
        .pipe(finalize(() => this.loadingFullLocation = false))
        .subscribe(
            subLocation => this.extendLocationPath(subLocation),
                e => {
              console.error(e);
              this.toastrService.error('Could not load sub location. Please try again.', 'Error');
              }
        );
  }

  public chooseLocation(): void {
    const lastSubLocation = last(this.locationPath);
    const parentLocations = this.locationPath.length < 2 ? [] : this.locationPath.slice(0, -1);

    // Construct FullLocation out of data that we have
    const pickedLocation = new FullLocation({
      ...lastSubLocation?.location,
      immediateSublocations: [...lastSubLocation.subLocations],
      fullLocationPath: parentLocations.map(p => new LocationWithSublocations(p.location)),
    });

    if (this.cancellable) {
      const event: CancellableEvent<FullLocation> = {
        cancel: false,
        payload: pickedLocation
      };
      this.locationChange.emit(event);
      if (event.cancel) {
        return;
      }
      this.close();
    } else {
      if (pickedLocation) {
        this.locationChange.emit(pickedLocation);
      }
      this.close();
    }
  }

  public selectSearchResult(searchResult: LocationSearchResult) {
    if (!searchResult) {
      return;
    }
    this.loadingFullLocation = true;
    // Search result is not a full location so we need to re-read it
    this.locationService.getFullLocationById(searchResult.location.id, this.account?.id, true)
        .pipe(finalize(() => this.loadingFullLocation = false))
        .subscribe(location => {
          if (this.cancellable) {
            const event: CancellableEvent<FullLocation> = {
              cancel: false,
              payload: location
            };
            this.locationChange.emit(event);
            if (event.cancel) {
              return;
            }
          } else {
            this.locationChange.emit(location);
          }
          this.removeLocationSelectorDataAfter(null);
          this.close();
          this.ngSelectComponent.clearModel();
        });
  }

  // Dummy search function is passed to ng-select to prevent the ng-select component to apply additional filtering on the search result.
  public dummySearchFunction(): boolean {
    return true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.autoHide && !this.isHidden && !this.eRef?.nativeElement?.contains(event?.target)) {
      this.close();
    }
  }

  private removeLocationSelectorDataAfter(locationSelectorData: LocationSelectorData): void {
    const locationDataIndex = this.locationPath.indexOf(locationSelectorData);
    if (locationDataIndex < 0) {
      this.locationPath = [];
    } else {
      this.locationPath = this.locationPath.slice(0, locationDataIndex + 1);
    }
  }

  private search(searchTerm: string): void {
    this.searchTerm = isString(searchTerm) ? searchTerm : '';
    if (this.searchTerm.length < 2) {
      return;
    }
    this.loadingSearchResult = true;
    this.locationService.searchLocations(searchTerm, true)
        .pipe(finalize(() => this.loadingSearchResult = false))
        .subscribe(
            locations => this.selectDropdownItems = locations,
            e => console.error(e)
        );
  }

  public close() {
    this._isHidden = true;
    this.isHiddenChange.emit(true);
  }

  private setLocationPath(location: FullLocation): void {
    this.locationPath = location.fullLocationPath
        .concat(location)
        .map(l => this.createLocationData(l));
    for (let i = 0; i < this.locationPath.length - 1; i++) {
      this.locationPath[i].selectedSublocation =
          this.locationPath[i].subLocations.find(l => l.id === this.locationPath[i + 1].location.id);
    }
  }

  private extendLocationPath(location: FullLocation) {
    this.locationPath.push(this.createLocationData(location));
  }

  private createLocationData(location: LocationWithSublocations) {
    const sublocationTypes = location.immediateSublocations.reduce((types, sublocation) => (
      {...types, [sublocation.type]: true}
    ), {} as Record<string, boolean>);
    return {
      label: location.immediateSublocations.length > 0 ? Object.keys(sublocationTypes).join(' / ') : '',
      location,
      subLocations: location.immediateSublocations,
      selectedSublocation: null
    };
  }
}

interface LocationSelectorData {
  label: string;
  location: Location;
  subLocations: Location[];
  selectedSublocation: Location | null;
}
