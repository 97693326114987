import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from './services/auth.service';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {getApolloOptions} from './config/apollo.config';
import {HttpLink} from 'apollo-angular/http';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';


/**
 * ServiceModule defines and configures application wide services, both internal and third-party services
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    })
  ],
  exports: [
  ]
})
export class ServiceModule {
  public static forRoot(): ModuleWithProviders<ServiceModule> {
    return {
      ngModule: ServiceModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory: getApolloOptions,
          deps: [HttpLink, AuthService]
        }
      ]
    };
  }
}
