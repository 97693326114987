import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { User } from '../../service/service.model';
import { UserService } from '../../service/services/user.service';

/**
 * This structural directive renders a view based on the fact is user is authenticated or not.
 * If uvcIsAuthenticated="true" then view is rendered only if user is authenticated.
 * If uvcIsAuthenticated="false" then view is rendered only if user is NOT authenticated.
 *
 * I don't user AuthService.isAuthenticated as that looks into local storage at the token.
 * It is very slow and it is not reactive i.e. I will not know when user logs in/out.
 * Instead I hook into currentUser$ observable. If we have current user it means user authenticated.
 */
@Directive({
  selector: '[uvcIsAuthenticated]'
})
export class IsAuthenticatedDirective implements OnInit, OnDestroy {

  private destroySubject = new Subject<boolean>();
  private destroy$ = this.destroySubject.asObservable();
  private mustBeAuthenticated = new ReplaySubject<boolean>(1);
  private view: ViewRef = null;

  @Input()
  set uvcIsAuthenticated(value: any) {
    this.mustBeAuthenticated.next(value === true || value === '' || value === 'true');
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private userService: UserService
  ) {
    // the default value is true
    // this allows writing e.g. <div *uvcIsAuthenticated> and it means the same as <div *uvcIsAuthenticated="true">
    this.mustBeAuthenticated.next(true);
  }

  ngOnInit() {
    combineLatest([this.userService.currentUser$().pipe(
      // this actualy means that user is not authenticated
      startWith(null as User),
      map(acct => !!acct)
    ), this.mustBeAuthenticated.asObservable()])
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(([isAuthenticated, mustBeAuthenticated]) => {
      const show = mustBeAuthenticated && isAuthenticated || !mustBeAuthenticated && !isAuthenticated;
      if (show && !this.view) {
        this.view = this.vcr.createEmbeddedView(this.templateRef);
      } else if (!show && this.view) {
        this.vcr.remove(this.vcr.indexOf(this.view));
        this.view = null;
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject.complete();
    this.destroySubject.unsubscribe();
  }

}
