import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPreferencesDialogComponent } from './components/user-preferences-dialog/user-preferences-dialog.component';
import { ModalModule } from '../modal/modal.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccountPickerComponent } from './components/account-picker/account-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';



@NgModule({
  declarations: [
    UserPreferencesDialogComponent,
    AccountPickerComponent,
  ],
  imports: [
    CommonModule,
    ModalModule,
    TooltipModule,
    ReactiveFormsModule,
    ToastrModule,
  ],
  exports: [UserPreferencesDialogComponent]
})
export class UserPreferencesModule { }
