import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticatedComponent} from './pages/authenticated/authenticated.component';
import {ModalModule} from '../widget/modal/modal.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    AuthenticatedComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    RouterModule
  ],
  exports: [
    AuthenticatedComponent
  ]
})
export class UserModule { }
