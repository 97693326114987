import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from 'src/app/common/components';

/**
 * This is a component for modal dialogs.
 * This modal can be opened only once. After it is closed the resultSubject is completed.
 */
@Component({
  selector: 'uvc-base-modal',
  templateUrl: './base-modal.component.html',
})
export class BaseModalComponent<Result> extends AbstractComponent implements OnDestroy {

  @Input() title: string;
  @Input() classes = ['uvc-modal'];
  @Input() header: TemplateRef<any>;
  @Input() footer: TemplateRef<any>;
  @Input() showClose = true;

  protected resultSubject = new Subject<Result>();

  result = this.resultSubject.asObservable();

  constructor(protected modalRef: BsModalRef) {
    super();
    // Close resultSubject if someone hides modal externally via modalRef
    modalRef.onHidden.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      if (!this.resultSubject.isStopped) {
        this.resultSubject.complete();
      }
    });
  }

  /**
   * Close modal without emitting any result
   */
  dismiss() {
    this.resultSubject.complete();
    this.modalRef.hide();
  }

  /**
   * Close modal with a result value
   * @param result result to emit
   * @param hide true will close modal immediatelly, false will let the modal stay open and external process
   * will be responsible for closing it.
   */
  close(result: Result, hide = true): void {
    this.resultSubject.next(result);
    this.resultSubject.complete();
    if (hide) {
      this.modalRef.hide();
    }
  }
}
