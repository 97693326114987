import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AbstractComponent } from 'src/app/common/components';
import { CancellableEvent } from 'src/app/common/models/cancellable-event';
import { FullLocation, UserAccount } from 'src/app/service/service.model';
import { LocationService } from 'src/app/service/services/location.service';

const MAX_LOCATIONS_LIMIT = 5;
@Component({
  selector: 'uvc-multi-location-picker',
  templateUrl: './multi-location-picker.component.html',
  styleUrls: ['./multi-location-picker.component.scss']
})
export class MultiLocationPickerComponent extends AbstractComponent implements OnInit {

  @Input()
  account: UserAccount;

  @Input()
  maxLocations = MAX_LOCATIONS_LIMIT;

  @Output()
  changeLocations = new EventEmitter<FullLocation[]>();

  showPicker = false;

  @Input()
  locations: FullLocation[] = [];

  constructor(
    protected locationService: LocationService,
    protected toast: ToastrService,
  ) {
    super();
  }

  openLocationPicker() {
    this.showPicker = true;
  }

  addLocation(event: CancellableEvent<FullLocation>) {
    if (this.locations.some(loc => loc.id === event.payload.id)) {
      // show error
      event.cancel = true;
      this.toast.error('Selected location is already used', 'Can not select location');
    } else {
      this.locations.push(event.payload);
      this.showPicker = false;
      this.notifyLocationsChange();
    }
  }

  changeLocation(index: number, event: CancellableEvent<FullLocation>) {
    if (this.locations.some((loc, idx) => loc.id === event.payload.id && idx !== index)) {
      // show error
      event.cancel = true;
      this.toast.error('Selected location is already used', 'Can not select location');
    } else {
      this.locations[index] = event.payload;
      this.notifyLocationsChange();
    }
  }

  removeLocation(location: FullLocation) {
    const idx = this.locations.findIndex(loc => loc.id === location.id);
    if (idx >= 0) {
      this.locations.splice(idx, 1);
      this.notifyLocationsChange();
    }
  }

  ngOnInit(): void {
    // Initialize list with current location
    if (!this.locations) {
      this.locationService.currentLocation().pipe(
        first()
      ).subscribe(location => {
        this.locations = [location];
        this.notifyLocationsChange();
      });
    }
  }

  notifyLocationsChange() {
    this.changeLocations.emit([...this.locations]);
  }

  identifyLocation(index: number, item: FullLocation) {
    return item.id;
  }

  prevParentLocation() {
    if (!this.locations.length || !this.locations[this.locations.length - 1]?.fullLocationPath.length) {
      return null;
    }
    const prevLoc = this.locations[this.locations.length - 1];
    const parentWithSublocation = prevLoc.fullLocationPath[prevLoc.fullLocationPath.length - 1];
    return new FullLocation({
      ...parentWithSublocation,
      fullLocationPath: prevLoc.fullLocationPath.length > 1 ? prevLoc.fullLocationPath.slice(0, -1) : [],
      immediateSublocations: parentWithSublocation.immediateSublocations,
    });
  }

}
