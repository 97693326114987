import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of, Observable } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, take, tap } from 'rxjs/operators';
import { PaginatedInput, PaginatedResponse, UserAccount, UserAccountData } from 'src/app/service/service.model';
import { StorageService } from 'src/app/service/services/storage.service';
import { UserService } from 'src/app/service/services/user.service';
import { BaseModalComponent } from '../base-modal/base-modal.component';

interface LastUsedAccount {
  accountId: string;
  accountName: string;
}

/**
 * Modal for searching accounts.
 *
 * Beware: The returned UserAccount contains very little data.
 * If you need more that account id & name you must
 * use UserService.getAccount() to get all details.
 */
@Component({
  selector: 'uvc-search-account-modal',
  templateUrl: './search-account-modal.component.html',
  styleUrls: ['./search-account-modal.component.scss'],
  animations: [
    trigger('growNShrink', [
      transition(':enter', [
        style({ height: '0' }),
        animate('.250s', style({ height: '*' })),
      ]),
      transition(':leave', [
        animate('.250s', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class SearchAccountModalComponent extends BaseModalComponent<UserAccount> implements OnInit, OnDestroy {

  @Input()
  staticData: UserAccount[] = null;

  /**
   * List of account ids that can not be selected
   */
  @Input()
  doNotSelect: string[];

  @Input()
  showLastUsedAccounts = false;
  lua: LastUsedAccount[] = [];

  readonly PAGE_SIZE = 10;

  typeaheadInput = new FormControl('');
  pagedResults: PaginatedResponse<UserAccount, UserAccountData>;
  loading = false;
  loadingAccount = false;
  lastSelectedId = null;
  prevQuery = null;
  beError: any;

  constructor(
    protected modalRef: BsModalRef,
    private userService: UserService,
    private storageService: StorageService,
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    if (this.showLastUsedAccounts) {
      this.lua = this.storageService.getLastUsedAccounts();
    }
    this.typeaheadInput.valueChanges.pipe(
      debounceTime(500),
      tap(() => this.pagedResults = null),
      switchMap(searchedText => this.searchAccounts$(searchedText)),
    ).subscribe();
    this.loadNextPage();
  }

  searchAccounts$(query?: string) {
    if (query || query === '') {
      this.prevQuery = query;
    } else if (this.prevQuery) {
      query = this.prevQuery;
    }

    const page: PaginatedInput = {
      maxResults: this.PAGE_SIZE,
      nextToken: this.pagedResults?.nextToken,
    };

    this.loading = true;
    this.beError = null;

    let source$: Observable<PaginatedResponse<UserAccount, UserAccountData>>;

    if (this.staticData) {
      const locaseQuery = query?.toLowerCase();
      const filteredAccounts = this.staticData.filter(acc => !query || acc.name.toLowerCase().indexOf(locaseQuery) >= 0);
      source$ = of(new PaginatedResponse<UserAccount, UserAccountData>({
        items: filteredAccounts,
        nextToken: null,
        totalCount: filteredAccounts.length,
      }, UserAccount));
    } else {
      source$ = this.userService.getAccounts(query, null, page);
    }

    return source$.pipe(
      tap(response => {
        if (this.pagedResults) {
          // user clicked "show more results"
          // keep previous results and add the new ones
          this.pagedResults = this.pagedResults.concat(response, UserAccount);
        } else {
          this.pagedResults = response;
        }
      }),
      catchError(error => {
        this.beError = error;
        this.pagedResults = null;
        return of(null);
      }),
      finalize(() => this.loading = false),
    );
  }

  loadNextPage() {
    this.searchAccounts$().pipe(
      take(1)
    ).subscribe();
  }

  emitSelectAccount(account: UserAccount | LastUsedAccount) {
    const acc = account instanceof UserAccount ? account : new UserAccount({id: account.accountId, name: account.accountName});
    this.loadingAccount = true;
    this.lastSelectedId = acc.id;
    this.close(acc, false);
  }

}
