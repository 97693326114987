import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';

export interface ActionProperties {
  label: string;
  cssClass: string[];
  value: any;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent extends BaseModalComponent<any> implements OnInit {

  @ViewChild('parent', { static: true }) baseModal: BaseModalComponent<any>;

  @Input() actions: ActionProperties[];
  @Input() message: string;

  ngOnInit(): void {
    this.baseModal.showClose = false;
    if (!this.actions) {
      this.actions = [{
        label: 'No',
        cssClass: ['btn', 'btn-outline-dark'],
        value: false
      }, {
        label: 'Yes',
        cssClass: ['btn', 'btn-primary'],
        value: true
      }];
    }
  }

  doConfirm(value): void {
    this.close(value);
  }
}
