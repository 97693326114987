import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthenticatedComponent } from '../user/pages/authenticated/authenticated.component';
import { AuthenticatedGuard, RoleRouteData, RoutingGuard } from './router.guard';
import { AccountResolvingRoutingGuard } from './account-resolving-router.guard';
import { RedirectingRoutingGuard } from './redirecting-router.guard';
import { ValidLocationInUrlGuard } from './valid-location-in-url.guard';
import { ROLE_KEYS, UserRole } from '../service/service.model';

export const AUTH_CALLBACK_PATH = 'callback/authenticated';

const routes: Routes = [
  {
    /**
     * ALL ROUTES THAT MUST BE AUTHENTICATED MUST BE CHILDREN OF THIS ROUTE
     */
    path: 'account/:accountId',
    pathMatch: 'prefix',
    // this guard checks that user is logged but also resolves account+location from URL
    canActivate: [AccountResolvingRoutingGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    children: [
      {
        path: 'dashboard',
        canActivate: [RoutingGuard, ValidLocationInUrlGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        loadChildren: () =>
          import('../routed/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'devices',
        canActivate: [RoutingGuard],
        loadChildren: () =>
          import('../routed/devices/devices.module').then(
            (m) => m.DevicesModule
          ),
      },
      {
        path: 'reports',
        canActivate: [RoutingGuard, ValidLocationInUrlGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        loadChildren: () =>
          import('../routed/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: 'partner',
        loadChildren: () =>
          import('../routed/partner/partner.module').then(
            (m) => m.PartnerModule
          ),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('../routed/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'chart',
        canActivate: [RoutingGuard],
        loadChildren: () =>
          import('../routed/chart-detail/chart-detail.module').then(
            (m) => m.ChartDetailModule
          ),
      },
      // Any unknown path
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'uvaadmin',
    canActivate: [RoutingGuard],
    data: {
      allowedRoles: ROLE_KEYS.map(role => ({
          role: UserRole[role],
          type: 'GLOBAL',
        } as RoleRouteData)),
    },
    loadChildren: () => import('../routed/uvangel-admin/uvangel-admin.module').then(m => m.UVAngelAdminModule),
  },
  /**
   * PUBLIC AND UTILITY ROUTES
   */
  {
    path: '2d',
    data: { isPublic: true },
    loadChildren: () =>
      import('../routed/public-device-info/public-device-info.module').then(
        (m) => m.PublicDeviceInfoModule
      ),
  },
  {
    // This route is used only to finish authentication process
    path: AUTH_CALLBACK_PATH,
    component: AuthenticatedComponent,
    canActivate: [AuthenticatedGuard],
  },

  // This route will try to load current account from local storage and retry URL prefixed with found account(+location) id
  // e.g.: dashboard -> account/1234/dashboard?locationId=xyz
  {
    path: '**',
    canActivate: [RedirectingRoutingGuard],
    component: AuthenticatedComponent,
    pathMatch: 'full'
  },
];

if (environment.enableEndpointsForE2E) {
  routes.unshift({
    path: 'e2e',
    loadChildren: () =>
      import('../routed/e2e/e2e.module').then((m) => m.E2eModule),
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [AuthenticatedGuard, RoutingGuard],
  exports: [RouterModule],
})
export class RoutingModule {}
