<uvc-base-modal [title]="title">
    <div class="form-group">
        <label for="defaultAccount" i18n="@@user-preferences-modal_default-account-label">
            Default Account
            <span
                class="icon icon-info"
                tooltip="Users with multiple accounts can select which one is selected by default"
                container="body"
                triggers="hover click focus"></span>
        </label>
        <uvc-account-picker *ngIf="!loadingAccount; else loader"
            id="defaultAccount"
            [staticData]="user.accounts"
            [formControl]="defaultAccountCtrl"
        ></uvc-account-picker>
    </div>
</uvc-base-modal>
<ng-template #loader>
    <span class="fa-spin fas fa-circle-notch spinner"></span>
</ng-template>