import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {AppSidebarModule} from '@coreui/angular';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from '../modal/modal.module';
import { ModalModule as BsModalModule } from 'ngx-bootstrap/modal';
import {AvatarModule} from 'ngx-avatar';
import {RouterModule} from '@angular/router';
import {SideBarComponent} from './components/sidebar/sidebar.component';
import {LocationPresenterComponent} from './components/location-presenter/location-presenter.component';
import {LocationPickerComponent} from './components/location-picker/location-picker.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MultiLocationPickerComponent } from './components/multi-location-picker/multi-location-picker.component';
import { IconsModule } from '../icons/icons.module';
import { RouterLinkActiveDirective } from './directives/router-link-active.directive';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UserPreferencesModule } from '../user-preferences/user-preferences.module';

@NgModule({
  declarations: [
    HeaderComponent,
    SideBarComponent,
    LocationPresenterComponent,
    LocationPickerComponent,
    MultiLocationPickerComponent,
    RouterLinkActiveDirective,
  ],
  imports: [
    AppSidebarModule,
    AvatarModule,
    BsDropdownModule,
    CommonModule,
    FormsModule,
    ModalModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    PopoverModule,
    IconsModule,
    BsModalModule.forChild(),
    TypeaheadModule,
    UserPreferencesModule,
  ],
  exports: [
    HeaderComponent,
    SideBarComponent,
    LocationPresenterComponent,
    LocationPickerComponent,
    MultiLocationPickerComponent,
  ]
})
export class NavigationModule { }
