<div *ngFor="let location of locations; let i = index; trackBy:identifyLocation" class="location-wrapper" [class.bottom-divider]="i < locations.length - 1">
    <uvc-location-presenter
        (locationChange)="changeLocation(i, $event)"
        [cancellable]="true"
        [location]="location"
        [loadingWhenEmpty]="true">
    </uvc-location-presenter>
    <span *ngIf="locations.length > 1" class="fas fa-times text-danger hand" (click)="removeLocation(location)"></span>
</div>
<uvc-location-picker *ngIf="showPicker"
    [autoHide]="false"
    [isHidden]="false"
    [account]="account"
    [cancellable]="true"
    class="location-picker"
    [preselectLocation]="prevParentLocation()"
    (isHiddenChange)="showPicker = false"
    (locationChange)="addLocation($event)">
</uvc-location-picker>
<button *ngIf="!showPicker"
    class="btn btn-primary mt-1"
    data-test-id="add-location-btn"
    [disabled]="locations.length >= maxLocations"
    (click)="showPicker = true"
    i18n="@@multilocation-picker_add-location">
Add Location
</button>