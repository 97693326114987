import {HttpLink} from 'apollo-angular/http';
import {AuthService} from '../services/auth.service';
import {InMemoryCache, ApolloClientOptions, NormalizedCacheObject} from '@apollo/client/core';
import {environment} from '../../../environments/environment';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {HttpHeaders} from '@angular/common/http';
import {ApolloLink, Operation, FetchResult} from '@apollo/client/link/core';
import {Observable} from '@apollo/client/utilities';

const GRAPHQL_URI = '/graphql';

export function getApolloOptions(
    httpLink: HttpLink,
    authService: AuthService
): ApolloClientOptions<NormalizedCacheObject> {
    const configuredHttpLink = getConfiguredHttpLink(httpLink);
    const authMiddleware = getAuthMiddleWare(authService);
    const errorMiddleWare = getErrorMiddleWare(authService);

    return {
        link: errorMiddleWare.concat(authMiddleware.concat(configuredHttpLink)),
        cache: new InMemoryCache({}),
        connectToDevTools: environment.enableDevTools || false,
        defaultOptions: {
            query: {
                errorPolicy: 'none'
            }
        },
        name: 'UVA_WEB_ANGULAR',
        version: environment.env === 'production' ? environment.version : `${environment.version}-${environment.env}`,
    };
}

function getConfiguredHttpLink(httpLink: HttpLink): ApolloLink {
    return httpLink.create({ uri: (op: Operation) => {
        return `${GRAPHQL_URI}?op=${op.getContext().operationName || op.operationName}`;
    }});
}

function getAuthMiddleWare(authService: AuthService): ApolloLink {
    return setContext(async (_, { headers }) => {
        return { headers: new HttpHeaders().set('Authorization', authService.getApiToken()) };
    });
}

function getErrorMiddleWare(authService: AuthService): ApolloLink {
    return onError((error: ErrorResponse) => {
        const networkError = error.networkError as any;
        if (error.graphQLErrors?.find(e => e.extensions?.code === 'UNAUTHENTICATED') ||
            // UNAUTHENTICATED code should not come as networkError but since our Apollo Server returns 400 status it is there.
            networkError?.error?.errors?.length > 0 && networkError.error.errors[0].extensions?.code === 'UNAUTHENTICATED'
        ) {
            console.debug('[Apollo] GraphQL not authenticated properly');
            return refreshTokenAndRetry(authService, error);
        } else {
            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(err => console.warn(err.message, err));
            }
            if (error.networkError) {
                console.warn(error.networkError);
            }
        }
    });
}

function refreshTokenAndRetry(authService: AuthService, error: ErrorResponse): Observable<FetchResult> {
    // Create an Apollo Observable, and wrap our RxJS observable in there
    return new Observable(sink => {
        authService.refreshTokenOrRedirectToLogin().subscribe(
            authenticated => sink.next(authenticated),
            err => sink.error(err)
        );
    }).flatMap(authenticated => {
        if (authenticated) {
            error.operation.setContext({
                headers: {
                    ...error.operation.getContext().headers,
                    Authorization: authService.getApiToken()
                }
            });
            return error.forward(error.operation);
        }
    });

}
