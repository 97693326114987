import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsAuthenticatedDirective } from './is-authenticated.directive';



@NgModule({
  declarations: [IsAuthenticatedDirective],
  imports: [
    CommonModule
  ],
  exports: [
    IsAuthenticatedDirective,
  ]
})
export class ProvidersModule { }
