<div class="text-left card">
  <div class="location card-body pb-0">
    <ng-select class="location-search-bar"
               placeholder="Search locations..."
               i18n-placeholder="@@location-picker_search-placeholder"
               clearAllText="Clear all"
               bindLabel="location.name"
               i18n-clearAllText="@@location-picker_search-clear-all"
               [loading]="loadingSearchResult"
               [openOnEnter]="false"
               [searchFn]="dummySearchFunction"
               [items]="selectDropdownItems"
               (click)="$event.stopPropagation()"
               (search)="searchDebounced($event)"
               (change)="selectSearchResult($event)"
               data-test-id="location-search">
      <ng-template ng-notfound-tmp>
        <div class="p-3 text-body">
          <span *ngIf="searchTerm.length < 2" i18n="@@location-picker_search-start-typing" data-test-id="location-search-start-typing">Start typing to search for locations</span>
          <span *ngIf="searchTerm.length >= 2" i18n="@@location-picker_search-empty-result" data-test-id="location-search-empty-result">No matching locations found</span>
          <ul class="search-hints-list m-0 pl-3">
            <li i18n="@@location-picker_search-hint-1">Use quotes to match exact strings: <kbd>Room "234"</kbd></li>
            <li i18n="@@location-picker_search-hint-2">Use hyphens to exclude words: <kbd>Main -Office</kbd></li>
          </ul>
        </div>
      </ng-template>
      <ng-template ng-loadingspinner-tmp>
        <span class="search-spinner fa-spin fas fa-circle-notch mr-2"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="search-custom-option" data-test-id="location-search-result-item">
          <div><small><span *ngFor="let location of item.locationPath; last as isLast">{{location.name}}{{isLast ? '' : ' / '}}</span></small></div>
          <div><strong>{{item.location.name}}</strong></div>
          <div *ngIf="item.location.tags?.length > 0"><small><span *ngFor="let tag of item.location.tags" class="badge badge-pill badge-primary mr-1">{{tag}}</span></small></div>
          <div *ngIf="item.location.description?.length > 0" class="ellipsis-x"><small>{{item.location.description}}</small></div>
        </div>
      </ng-template>
    </ng-select>
    <p class="divider" i18n="@@location-picker_divider">Or select it in the hierarchy</p>
    <div *ngFor="let locationSelectorData of locationPath" class="my-3">
      <div *ngIf="locationSelectorData.subLocations.length > 0">
        <label class="text-body location-type-label">{{locationSelectorData.label}}</label>
        <select data-test-id="location-picker-combobox"
                class="d-block form-control"
                [disabled]="loadingSearchResult || loadingFullLocation"
                [(ngModel)]="locationSelectorData.selectedSublocation"
                (ngModelChange)="selectSublocation(locationSelectorData)">
          <option [ngValue]="null"><ng-container i18n="@@location-picker_combobox-any">All</ng-container></option>
          <option *ngFor="let subLocation of locationSelectorData.subLocations"
                  [ngValue]="subLocation">{{ subLocation.type }} - {{ subLocation.name }}
          </option>
        </select>
      </div>
    </div>
    <div [class]="{active: loadingFullLocation}" class="loading-container align-items-center">
      <span class="fa-spin fas fa-circle-notch text-body fa-lg mr-2"></span>
    </div>
  </div>

  <div class="d-flex justify-content-between card-footer">
    <span *ngIf="autoHide"></span>
    <button *ngIf="!autoHide"
      data-test-id="location-picker-cancel-btn"
      class="btn btn-secondary"
      type="button"
      (click)="close()"
      i18n="@@location-picker_cancel-button">Cancel</button>
    <button *ngIf="locationPath.length > 0"
            data-test-id="location-picker-select-btn"
            class="btn btn-primary"
            type="button"
            [disabled]="loadingSearchResult || loadingFullLocation"
            (click)="chooseLocation()"
            i18n="@@location-picker_select-button">Select</button>
  </div>
</div>
